<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline banner-login text-center bgoverlay">
        <RecoveryForm />
    </div>
    <HomeFooter />
  </b-container>
</template>

<script>
// @ is an alias to /src
import RecoveryForm from "@/components/RecoveryForm.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "Recovery",
  components: {
    RecoveryForm,
    DashboardHeader,
    HomeFooter,
  },
};
</script>

<style scoped>
.bgoverlay {
  /*background: linear-gradient(0deg, rgba(0, 161, 142, 0.3), rgba(0, 161, 142, 0.3)), url('../assets/img/students-knowing-right-answer-min.jpg');*/
  background: linear-gradient(
      0deg,
      rgba(0, 161, 142, 0.3),
      rgba(0, 161, 142, 0.3)
    ),
    url("../assets/img/bg-login02.jpg");
  background-size: cover;
  background-position: center right;
}
</style>