<template>
  <div>
    <div class="reginfo2">
      <div class="infotitle">
        <b-container>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col cols="11" class="text-left">
              <h4>IDENTITAS CALON SISWA</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <form @submit.prevent="handleSubmit">
        <input type="hidden" v-model="form.id_reg" />
        <input type="hidden" v-model="form.id_user" />
        <div class="infocontent2">
          <div style="max-width: 900px; margin: auto">
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputFullname"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nama Lengkap</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.fullname"
                  name="fullname"
                  class="form-control form-control-lg"
                  id="inputFullname"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputNickname"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nama Panggilan</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.nickname"
                  name="nickname"
                  class="form-control form-control-lg"
                  id="inputNickname"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputBirthplace"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tempat Lahir</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.birthplace"
                  name="birthplace"
                  class="form-control form-control-lg"
                  id="inputBirthplace"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputBirthdate"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tanggal Lahir</b></label
              >
              <div class="col-md-5">
                <div class="input-group">
                  <flat-pickr
                    v-model="form.birthdate"
                    :config="config"
                    class="form-control form-control-lg"
                    placeholder="Select date"
                    name="date"
                    disabled
                  >
                  </flat-pickr>
                  <div class="input-group-btn">
                    <button
                      class="btn btn-lg btn-dark"
                      type="button"
                      title="Toggle"
                      data-toggle
                    >
                      <b-icon icon="calendar3"></b-icon>
                    </button>
                  </div>
                </div>
                <!--
                <b-form-datepicker
                  id="inputBirthdate"
                  v-model="form.birthdate"
                  class="mb-2"
                  required
                ></b-form-datepicker>
                -->
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputGender"
                class="col-md-2 col-form-label color-tosca"
                ><b>Jenis Kelamin</b></label
              >
              <div class="col-md-10 text-left">
                <div class="form-check form-check-inline">
                  <input
                    v-model="form.gender"
                    name="gender"
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio1"
                    value="Laki-laki"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Laki-Laki</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="form.gender"
                    name="gender"
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    value="Perempuan"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Perempuan</label
                  >
                </div>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputWni" class="col-md-2 col-form-label color-tosca"
                ><b>Kewarganegaraan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.wni"
                  name="wni"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputWni"
                  required
                >
                  <option
                    v-for="wni in wnis"
                    v-bind:key="wni.value"
                    v-bind:value="wni.value"
                  >
                    {{ wni.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputReligion"
                class="col-md-2 col-form-label color-tosca"
                ><b>Agama</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.religion"
                  name="religion"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputReligion"
                  required
                >
                  <option
                    v-for="religion in religions"
                    v-bind:key="religion.value"
                    v-bind:value="religion.value"
                  >
                    {{ religion.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputid_ppdb"
                class="col-md-2 col-form-label color-tosca"
                ><b>Sekolah Pilihan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_ppdb"
                  name="id_ppdb"
                  @change="changeUnitLevels"
                  class="form-control form-control-lg"
                  placeholder="Pilih Sekolah"
                  id="inputid_ppdb"
                  required
                  disabled
                >
                  <option
                    v-for="ppdb in ppdbs"
                    v-bind:key="ppdb.id"
                    v-bind:value="ppdb.id"
                    :data-id_unit="ppdb.id_unit"
                  >
                    {{ ppdb.desc }} ({{ ppdb.type }})
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputid_unitlevel"
                class="col-md-2 col-form-label color-tosca"
                ><b>Kelas Pilihan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_unitlevel"
                  name="id_unitlevel"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputid_unitlevel"
                  required
                  disabled
                >
                  <option
                    v-for="unitlevel in unitlevels"
                    v-bind:key="unitlevel.id"
                    v-bind:value="unitlevel.id"
                  >
                    {{ unitlevel.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputNik" class="col-md-2 col-form-label color-tosca"
                ><b>NIK*</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.nik"
                  name="nik"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputNik"
                  required
                  minlength="16"
                  maxlength="16"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputNikk" class="col-md-2 col-form-label color-tosca"
                ><b>NKK*</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.nikk"
                  name="nikk"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputNikk"
                  required
                  minlength="16"
                  maxlength="16"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputChildno"
                class="col-md-2 col-form-label color-tosca"
                ><b>Anak Ke*</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.childno"
                  name="childno"
                  type="number"
                  class="form-control form-control-lg"
                  id="inputChildno"
                  min="1"
                  max="20"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputSibling"
                class="col-md-2 col-form-label color-tosca"
                ><b>Jumlah Saudara Kandung*</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.sibling"
                  name="sibling"
                  type="number"
                  class="form-control form-control-lg"
                  id="inputSibling"
                  min="0"
                  max="20"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputStepsibling"
                class="col-md-2 col-form-label color-tosca"
                ><b>Jumlah Saudara Tiri*</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.stepsibling"
                  name="stepsibling"
                  type="number"
                  class="form-control form-control-lg"
                  id="inputStepsibling"
                  min="0"
                  max="20"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputLang" class="col-md-2 col-form-label color-tosca"
                ><b>Bahasa Sehari Hari*</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.lang"
                  name="lang"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputLang"
                  required
                >
                  <option
                    v-for="lang in langs"
                    v-bind:key="lang.value"
                    v-bind:value="lang.value"
                  >
                    {{ lang.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="infocontent2">
          <div class="text-right">
            <div>
              <button class="btn btn-info btn-lg" type="submit">
                {{ displayLoadingSubmit }}
              </button>
              <br />
              <br />
              <small
                ><i class="text-danger">{{ displayError }}</i></small
              >
              <i class="text-success">{{ displaySuccess }}</i>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "MainProfileStudent",
  components: {
    flatPickr,
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      auth: localStorage.getItem("auth"),
      token: localStorage.getItem("token"),
      reg: [],
      ppdbs: [],
      unitlevels: [],
      wnis: [
        {
          value: "WNI",
          name: "Warga Negara Indonesia (WNI)",
        },
        {
          value: "WNA",
          name: "Warga Negara Asing (WNA)",
        },
      ],
      religions: [
        {
          value: "Islam",
          name: "Islam",
        },
        {
          value: "Kristen",
          name: "Kristen",
        },
        {
          value: "Katolik",
          name: "Katolik",
        },
        {
          value: "Hindu",
          name: "Hindu",
        },
        {
          value: "Budha",
          name: "Budha",
        },
        {
          value: "Konghuchu",
          name: "Konghuchu",
        },
      ],
      langs: [
        {
          value: "Indonesia",
          name: "Indonesia",
        },
        {
          value: "Inggris",
          name: "Inggris",
        },
        {
          value: "Mandarin",
          name: "Mandarin",
        },
        {
          value: "Lainnya",
          name: "Lainnya",
        },
      ],
      loading_reg: "loading data..",
      displayLoadingSubmit: "Simpan Data",
      displayError: "",
      displaySuccess: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        id_ppdb: "",
        wni: "",
        birthplace: "",
        birthdate: "",
        fullname: "",
        nickname: "",
        gender: "",
        id_unitlevel: "",
        id_unit: "",
        religion: "",
        nik: "",
        nikk: "",
        childno: "",
        sibling: "",
        stepsibling: "",
        lang: "",
        id_reg: "",
        id_user: "",
      },
    };
  },
  created() {
    //this.getPPDBOpen();
    this.getReg();

    // update store refresh_headeruserinfo
    this.$store.commit("false_refresh_headeruserinfo");
  },
  methods: {
    async getReg() {
      this.dataAuth = JSON.parse(this.auth);
      this.form.id_user = this.dataAuth.id_user;
      this.form.id_reg = this.dataAuth.id_reg;

      try {
        const response = await axios.get(
          "/api/getmainprofilereg/" + this.form.id_reg,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.reg = response.data;
        // console.log('regsss',response.data.reg["id_unitlevel"])
        //console.log(response.data.data['fullname']);
        this.form.fullname = response.data.reg["fullname"];
        this.form.nickname = response.data.reg["nickname"];
        this.form.birthplace = response.data.reg["birthplace"];
        this.form.birthdate = response.data.reg["birthdate"];
        this.form.gender = response.data.reg["gender"];
        this.form.wni = response.data.reg["wni"];
        this.form.religion = response.data.reg["religion"];
        this.form.id_ppdb = response.data.reg["id_ppdb"];
        this.ppdbs = [
          {
            id: response.data.reg["id_ppdb"],
            desc: response.data.reg["ppdb_desc"],
            type: response.data.reg["ppdb_type"],
          }
        ]
        //console.log(this.ppdbs);

        // get id_unit for display select unitlevel
        this.form.id_unit = response.data.unitlevel["id_unit"];
        this.getUnitLevels(this.form.id_unit);
        this.form.id_unitlevel = response.data.reg["id_unitlevel"];

        this.form.nik = response.data.reg["nik"];
        this.form.nikk = response.data.reg["nikk"];
        this.form.childno = response.data.reg["childno"];
        this.form.sibling = response.data.reg["sibling"];
        this.form.stepsibling = response.data.reg["stepsibling"];
        this.form.lang = response.data.reg["lang"];

        this.loading_reg = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getPPDBOpen() {
      try {
        const response = await axios.get("/api/ppdb/external");
        this.ppdbs = response.data;
        //console.log(this.ppdbs);

        this.loading_ppdbs = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getUnitLevels() {
      try {
        const response = await axios.get("/api/unitlevels");
        // const response = await axios.get("/api/getunitlevelsold/" + id_unit);
        this.unitlevels = response.data;
        console.log('unitsl',this.unitlevels);

        this.loading_unitlevels = "";
      } catch (error) {
        console.log(error);
      }
    },

    changeUnitLevels(event) {
      var options = event.target.options;
      if (options.selectedIndex > -1) {
        this.id_unit =
          options[options.selectedIndex].getAttribute("data-id_unit");
        parseInt(this.id_unit);
        if (this.id_unit > 0) {
          //console.log(this.id_unit);
          // call function
          this.getUnitLevels(this.id_unit);
        }
      }
    },

    async handleSubmit() {
      this.displayLoadingSubmit = "processing...";
      try {
        const response = await axios({
          method: "put",
          url: "/api/update_mainprofilestudent/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.displayLoadingSubmit = "Submit Data";
        this.displaySuccess = this.dataupdate.message;
        Swal.fire("Good job!", "Data berhasil disimpan!<br />Lanjut ke pengisian <b>Data Pendidikan</b>", "success");
        
        // update store refresh_headeruserinfo
        this.$store.commit("true_refresh_headeruserinfo");

        this.$emit("rerender_headeruserinfo", false);
        setTimeout(() => {
          this.$emit("rerender_headeruserinfo", true);
          // rediret to next form
          this.$router.push({ path: "/educationinfo" });
        }, 2000);
      } catch (error) {
        console.log(error);
        this.displayError = error;
        this.displayLoadingSubmit = "Simpan Data";
        setTimeout(() => {
          this.displayError = "";
        }, 5000);
      }
    },
    makeToast(variant = null, setTitle, setBody) {
      this.$bvToast.toast(setBody, {
        title: setTitle,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style>
</style>