<template>
  <b-container style="max-width:1400px;">
    <HomeHeader/>
    <BannerHeadline/>
    <HomeFooter/>
  </b-container>
</template>

<script>
// @ is an alias to /src
import HomeHeader from "@/components/HomeHeader.vue";
import BannerHeadline from "@/components/BannerHeadline.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: 'Home',
  components: {
    HomeHeader,
    BannerHeadline,
    HomeFooter,
  },
}
</script>