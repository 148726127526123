<template>
  <div>
    <div class="reginfo2">
      <div class="infocontent2">
        <div v-if="loadingForm">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
      </div>
      <div v-if="showForm">
        <form @submit.prevent="handleSubmitChangePassword">
          <div class="infotitle">
            <b-container>
              <b-row>
                <b-col cols="1"> </b-col>
                <b-col cols="11" class="text-left">
                  <h4>Akun Login Orang Tua/Wali</h4>
                </b-col>
              </b-row>
            </b-container>
          </div>

          <div class="infocontent2">
            <div style="max-width: 900px; margin: auto">
              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Email</b></label
                >
                <div class="col-md-10">
                  <input
                    type="email"
                    :value="auth.email"
                    class="form-control form-control-lg"
                    disabled
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputpassword"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Ganti Password</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.password"
                    name="password"
                    type="password"
                    class="form-control form-control-lg"
                    id="inputpassword"
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button
                  pill
                  variant="info"
                  type="submit"
                  size="lg"
                  style="padding-left: 30px; padding-right: 30px"
                  >{{ submitLabel }}</b-button
                >
              </div>
            </div>
          </div>
        </form>

        <form @submit.prevent="handleSubmitChangeProfile">
          <div class="infotitle">
            <b-container>
              <b-row>
                <b-col cols="1"> </b-col>
                <b-col cols="11" class="text-left">
                  <h4>Identitas Orang Tua/Wali</h4>
                </b-col>
              </b-row>
            </b-container>
          </div>

          <div class="infocontent2">
            <div style="max-width: 900px; margin: auto">
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputpg_fullname"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Nama Pendaftar</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.fullname"
                    name="fullname"
                    type="text"
                    class="form-control form-control-lg"
                    id="inputpg_fullname"
                    required
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputTextB01"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Hubungan Pendaftar</b></label
                >
                <div class="col-md-10">
                  <b-form-select
                    v-model="form.id_relation"
                    name="id_relation"
                    size="lg"
                    :options="relations"
                  ></b-form-select>
                  <small
                    ><i class="text-muted">{{ loading_relations }}</i></small
                  >
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputbirthplace"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tempat Lahir</b></label
                >
                <div class="col-md-10">
                  <input
                    type="text"
                    v-model="form.birthplace"
                    name="birthplace"
                    class="form-control form-control-lg"
                    id="inputbirthplace"
                    required
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputbirthdate"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tanggal Lahir</b></label
                >
                <div class="col-md-5">
                  <div class="input-group">
                    <flat-pickr
                      v-model="form.birthdate"
                      :config="config"
                      class="form-control form-control-lg"
                      placeholder="Select date"
                      name="date"
                    >
                    </flat-pickr>
                    <div class="input-group-btn">
                      <button
                        class="btn btn-lg btn-dark"
                        type="button"
                        title="Toggle"
                        data-toggle
                      >
                        <b-icon icon="calendar3"></b-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputWni"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Kewarganegaraan</b></label
                >
                <div class="col-md-10">
                  <b-form-select
                    v-model="form.wni"
                    name="wni"
                    size="lg"
                    :options="wnis"
                    required
                  ></b-form-select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputReligion"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Agama</b></label
                >
                <div class="col-md-10">
                  <b-form-select
                    v-model="form.religion"
                    name="religion"
                    size="lg"
                    :options="religions"
                    required
                  ></b-form-select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputEdulevel"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Pendidikan</b></label
                >
                <div class="col-md-10">
                  <b-form-select
                    v-model="form.edulevel"
                    name="edulevel"
                    size="lg"
                    :options="edulevels"
                    required
                  ></b-form-select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputTextB01"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tipe Pekerjaan</b></label
                >
                <div class="col-md-10">
                  <b-form-select
                    v-model="form.id_work"
                    name="id_work"
                    size="lg"
                    :options="works"
                    required
                  ></b-form-select>
                  <small
                    ><i class="text-muted">{{ loading_works }}</i></small
                  >
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputaddress"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Alamat</b></label
                >
                <div class="col-md-10">
                  <textarea
                    id="inputaddress"
                    class="form-control form-control-lg"
                    v-model="form.address"
                    name="address"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Provinsi</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_province"
                    class="form-control form-control-lg"
                    @change="handleSelectCity"
                  >
                    <option
                      v-for="select in provinceselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kota/Kabupaten</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_city"
                    class="form-control form-control-lg"
                    @change="handleSelectDistrict"
                  >
                    <option
                      v-for="select in cityselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kecamatan</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_district"
                    class="form-control form-control-lg"
                    @change="handleSelectSubDistrict"
                  >
                    <option
                      v-for="select in districtselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kelurahan</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_subdistrict"
                    class="form-control form-control-lg"
                  >
                    <option
                      v-for="select in subdistrictselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kodepos</b></label
                >
                <div class="col-md-10">
                  <input
                    type="text"
                    v-model="form.postalcode"
                    name="postalcode"
                    class="form-control form-control-lg"
                    required
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputpg_cellphone"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Handphone</b></label
                >
                <div class="col-md-10">
                  <input
                    id="inputcellphone"
                    type="text"
                    v-model="form.cellphone"
                    name="cellphone"
                    class="form-control form-control-lg"
                    required
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button
                  pill
                  variant="info"
                  type="submit"
                  size="lg"
                  style="padding-left: 30px; padding-right: 30px"
                  >{{ submitLabel }}</b-button
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "ParentProfileForm",
  components: {
    flatPickr,
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      token: "",
      auth: "",
      fullname: "",
      id_user: "",
      regs: "",
      form: {
        password: "",
        cpassword: "",
        fullname: "",
        birthplace: "",
        birthdate: "",
        address: "",
        id_province: "",
        id_city: "",
        id_district: "",
        id_subdistrict: "",
        postalcode: "",
        cellphone: "",
        id_relation: "",
        id_work: "",
        wni: "",
        religion: "",
        edulevel: "",
      },
      submitLabel: "Simpan",
      showForm: false,
      loadingForm: false,
      parentprofile: [],
      pg_address: "",
      loading_relations: "",
      loading_works: "",
      provinceselect: [],
      cityselect: [],
      districtselect: [],
      subdistrictselect: [],
      infregs: [],
      works: [],
      relations: [],
      wnis: [
        {
          value: "WNI",
          text: "Warga Negara Indonesia (WNI)",
        },
        {
          value: "WNA",
          text: "Warga Negara Asing (WNA)",
        },
      ],
      religions: [
        {
          value: "Islam",
          text: "Islam",
        },
        {
          value: "Kristen",
          text: "Kristen",
        },
        {
          value: "Katolik",
          text: "Katolik",
        },
        {
          value: "Hindu",
          text: "Hindu",
        },
        {
          value: "Budha",
          text: "Budha",
        },
        {
          value: "Konghuchu",
          text: "Konghuchu",
        },
      ],
      edulevels: [
        {
          value: "SD",
          text: "Sekolah Dasar (SD)",
        },
        {
          value: "SMP",
          text: "Sekolah Menengah Pertama (SMP)",
        },
        {
          value: "SMA",
          text: "Sekolah Menengah Atas (SMA)",
        },
        {
          value: "S1",
          text: "Sarjana (S1)",
        },
        {
          value: "S2",
          text: "Sarjana (S2)",
        },
        {
          value: "S3",
          text: "Sarjana (S3)",
        },
      ],
    };
  },
  created() {
    this.getDefaultVar();
    this.getDataParentProfile();
    this.getRelations();
    this.getWorks();
    this.getProvinceSelect();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      //let vuexAuth = this.$store.getters.auth;
      //console.log(vuexAuth);
      this.fullname = this.auth.fullname;
      this.email = this.auth.email;
      this.id_user = this.auth.id_user;
    },
    async getDataParentProfile() {
      this.loadingForm = true;
      try {
        const response = await axios.get("/api/get_data_parent_profile", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        
        this.parentprofile = response.data;
        for (let x in this.parentprofile) {
          this.form[x] = this.parentprofile[x];
        }
        //console.log(this.parentprofile);
        this.getCitySelect(this.form.id_province);
        this.getDistrictSelect(this.form.id_city);
        this.getSubDistrictSelect(this.form.id_district);

        this.showForm = true;
        this.loadingForm = false;
      } catch (error) {
        console.log(error);
        this.loadingForm = false;
      }
    },
    async getProvinceSelect() {
      try {
        const response = await axios.get("api/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.provinceselect = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCitySelect(id_province) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/city/select/" + id_province, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cityselect = response.data;
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getDistrictSelect(id_city) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/district/select/" + id_city, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.districtselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getSubDistrictSelect(id_district) {
      this.loadingModal = true;
      try {
        const response = await axios.get(
          "api/subdistrict/select/" + id_district,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.subdistrictselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getRelations() {
      try {
        const response = await axios.get("/api/select_relations");
        this.relations = response.data;
        //console.log(this.relations);

        this.loading_relations = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getWorks() {
      try {
        const response = await axios.get("/api/select_works");
        this.works = response.data;
        //console.log(this.relations);

        this.loading_works = "";
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.form.id_province);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.id_city);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.id_district);
    },

    async handleSubmitChangePassword() {
      this.submitLabel = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/change_parent_password",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.submitLabel = "Simpan";
        if (response.data.status == "success") {
          Swal.fire("Good Job!", "Password berhasil diupdate.", "success");
        } else {
          Swal.fire("Error!", response.data.message, "error");
        }
      } catch (error) {
        console.log(error);
        this.submitLabel = "Simpan";
      }
    },
    async handleSubmitChangeProfile() {
      this.submitLabel = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/update_parent_profile",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.submitLabel = "Simpan";
        if (response.data.status == "success") {
          // update session auth
          this.auth.fullname = this.form.fullname;
          let new_dataAuth = JSON.stringify(this.auth);
          localStorage.setItem("auth", new_dataAuth);
          // save to Vuex
          let newdataVuex = {
            auth: this.auth,
          };
          this.$store.commit("auth", newdataVuex);

          Swal.fire("Good Job!", "Data berhasil disimpan.", "success");

          setTimeout(() => {
            // rediret to update my profile
            this.$router.push({ path: "/reglist" });
          }, 2000);
        } else {
          Swal.fire("Error!", response.data.message, "error");
        }
      } catch (error) {
        console.log(error);
        this.submitLabel = "Simpan";
      }
    },
  },
};
</script>

<style>
</style>