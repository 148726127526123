import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import interceptorsSetup from './helpers/interceptors'

import {
  BootstrapVue,
  IconsPlugin,
  BIcon,
  BIconArrowUp,
} from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from './store'

interceptorsSetup();

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSweetalert2);

Vue.component("BIcon", BIcon);
Vue.component("BIconArrowUp", BIconArrowUp);

Vue.config.productionTip = false;

//axios.defaults.baseURL = "http://api-siadm.test"
//axios.defaults.baseURL = "http://api-siakads.test"
// axios.defaults.baseURL = "https://api.ypii.net"
//axios.defaults.baseURL = "http://localhost:8000"
axios.defaults.baseURL = "https://api.annisaa-izada.sch.id"
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
