<template>
  <div>
    <b-container style="max-width: 1400px">
      <DashboardHeader />
      <div class="banner-headline text-center">
        <div class="banner-headline-content">
          <h4 class="text-white text-center">
            SELAMAT DATANG, <i>{{ fullname }}</i>
            <small style="font-size: 16px"
              > | 
              <b-button size="sm" variant="dark" :to="{ path: '/reglist' }" class="text-white"
                >Tabel Siswa Terdaftar</b-button
              ></small
            ><br />
            <small>UPDATE MY PROFILE</small>
          </h4>
          <div class="reg-container mt-4">
            <ParentProfileForm />
          </div>
        </div>
      </div>
      <HomeFooter />
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
import ParentProfileForm from "@/components/ParentProfileForm.vue";
import HomeFooter from "@/components/HomeFooter.vue";
export default {
  name: "ParentProfile",
  components: {
    DashboardHeader,
    ParentProfileForm,
    HomeFooter,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      fullname: "",
      id_user: "",
      regs: "",
    };
  },
  created() {
    this.getDefaultVar();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
  },
};
</script>

<style>
</style>