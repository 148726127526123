<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline text-center">
      <div class="banner-headline-content">
        <h4 class="text-white text-center">PRINT BUKTI PENDAFTAR</h4>
        <div class="reg-container mt-4">
          <HeaderUserInfo />
          <br />
          <div class="dashboard-title">
            <h4>DASHBOARD</h4>
          </div>
          <br />
          <FlowMenuRegister />
          <div class="dashboard-content">
            <div class="text-right">
              <b-link @click="showpdfview">
                <b-icon
                  icon="printer"
                  alt="Printer"
                  font-scale="3"
                ></b-icon> </b-link
              >{{ " " }}
              <b-link :to="{ path: '/dashboard' }">
                <b-icon icon="x" variant="danger" font-scale="4"></b-icon>
              </b-link>
            </div>
            <b-overlay
              :show="loaderShow"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-card :aria-hidden="loaderShow ? 'true' : null">
                <div class="text-center">
                  <b-button variant="dark" size="lg" @click="showpdfview"
                    >Download Surat Kelulusan</b-button
                  >
                </div>
              </b-card>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <HomeFooter />
  </b-container>
</template>

<script>
import axios from "axios";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import HeaderUserInfo from "@/components/HeaderUserInfo.vue";
import FlowMenuRegister from "@/components/FlowMenuRegister.vue";

export default {
  name: "PassLetterInfo",
  components: {
    DashboardHeader,
    HomeFooter,
    HeaderUserInfo,
    FlowMenuRegister,
  },
  data() {
    return {
      loaderShow: false,
      showagreement: true,
      showwarning: false,
      basePath: axios.defaults.baseURL,
      urlparentagreement: "",
      id_reg: "",
    };
  },
  created() {
    this.getDefaultVar();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_reg = this.auth.id_reg;
      this.urlparentagreement =
        this.basePath + "/api/reg/passletter/" + this.id_reg;
    },
    showpdfview() {
      this.loaderShow = true;
      window.location.href=this.urlparentagreement;
    }
  },
};
</script>

<style>
</style>