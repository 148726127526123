<template>
  <div class="reginfo2">
    <div class="infotitle">
      <b-container>
        <b-row>
          <b-col cols="1"> </b-col>
          <b-col cols="10">
            <h4>VERIFIKASI SISWA INTERNAL</h4>
          </b-col>
          <b-col cols="1"> </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="infocontent">
      <br />
      <p class="text-center">
        <i>{{ emptySelectValidStudent }}</i>
      </p>
      <p class="text-center" v-if="showButtonGoToExternal">
        <b-overlay
          :show="loaderShow"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card :aria-hidden="loaderShow ? 'true' : null" style="background-color:#00a18e;">
            <div class="text-center">
              <b-button variant="dark" @click="GoToExternal"
                >Pilih Pendaftaran External</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </p>

      <div v-if="showSelectValidStudent">
        <b-row>
          <b-col cols="1"> </b-col>
          <b-col cols="10">
            <div class="form-group row">
              <label for="input02" class="col-sm-2 col-form-label"
                >Nama Siswa*</label
              >
              <div class="col-sm-10">
                <select
                  v-model="param_id_reg"
                  class="form-control form-control-lg"
                  placeholder="Pilih Siswa Terdaftar"
                  @change="changeParamIDReg"
                  required
                >
                  <option
                    v-for="reglist in reglists"
                    v-bind:key="reglist.id_reg"
                    v-bind:value="reglist.id_reg"
                    :data-unitsort="reglist.unitsort"
                  >
                    [{{ reglist.nis }}] {{ reglist.fullname }} (
                      {{ reglist.unitlevel_name}} - {{ reglist.classname}}
                      )
                  </option>
                </select>
              </div>
            </div>

            <div class="text-center">
              <b-button
                pill
                size="lg"
                @click="validateStudent"
                variant="primary"
                >VALIDASI SISWA</b-button
              >
            </div>
          </b-col>
          <b-col cols="1"> </b-col>
        </b-row>
      </div>
    </div>

    <div v-if="showForm">
      <form @submit.prevent="handleSubmit">
        <div class="infocontent2">
          <div style="max-width: 900px; margin: auto">
            <input type="hidden" v-model="form.selected_id_reg" />

            <h5>#Kelas Tujuan</h5>
         
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputText07"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tingkat Sekolah Tujuan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_ppdb"
                  name="id_ppdb"
                  @change="changeUnitLevels"
                  class="form-control form-control-lg"
                  placeholder="Pilih Sekolah"
                  required
                >
                  <option
                    v-for="ppdb in ppdbs"
                    v-bind:key="ppdb.id"
                    v-bind:value="ppdb.id"
                    :data-id_unit="ppdb.id_unit"
                  >
                  <!--  v-if="ppdb.unitsort > param_id_reg_unitsort" -->
                    <div>
                      {{ ppdb.desc }} ({{ ppdb.type }})
                    </div>
                  </option>
                </select>
                <small
                  ><i class="text-muted">{{ loading_ppdbs }}</i></small
                >
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputText07"
                class="col-md-2 col-form-label color-tosca"
                ><b>Kelas Tujuan </b></label
              >
              <div class="col-md-10">
                <b-row>
                  <b-col>
                    <select
                      v-model="form.id_unitlevel"
                      name="id_unitlevel"
                      class="form-control form-control-lg"
                      aria-label="Default select example"
                      required
                    >
                      <option
                        v-for="unitlevel in unitlevels"
                        v-bind:key="unitlevel.id"
                        v-bind:value="unitlevel.id"
                      >
                        {{ unitlevel.name }}
                      </option>
                    </select>
                    <small
                      ><i class="text-muted">{{ loading_unitlevels }}</i></small
                    >
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- <div class="mb-3 row form-input-label-right">
              <label
                for="inputText07"
                class="col-md-2 col-form-label color-tosca"
                ><b>Kelas Tujuan</b></label
              >
              <div class="col-md-10">
                <b-row>
                  <b-col>
                    <select
                      v-model="form.id_unitlevel"
                      name="id_unitlevel"
                      class="form-control form-control-lg"
                      aria-label="Default select example"
                      required
                    >
                      <option
                        v-for="unitlevel in unitlevels"
                        v-bind:key="unitlevel.id"
                        v-bind:value="unitlevel.id"
                      >
                        {{ unitlevel.name }}
                      </option>
                    </select>
                    <small
                      ><i class="text-muted">{{ loading_unitlevels }}</i></small
                    >
                  </b-col>
                </b-row>
              </div>
            </div> -->

            <hr />

            <h5>#Biodata Anak Terdaftar</h5>
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputfullname"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nama Lengkap</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.fullname"
                  name="fullname"
                  class="form-control form-control-lg"
                  id="inputfullname"
                  disabled
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputnickname"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nama Panggilan</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.nickname"
                  name="nickname"
                  class="form-control form-control-lg"
                  id="inputnickname"
                  disabled
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputbirthplace"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tempat Lahir</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.birthplace"
                  name="birthplace"
                  class="form-control form-control-lg"
                  id="inputbirthplace"
                  disabled
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputbirthdate"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tanggal Lahir</b></label
              >
              <div class="col-sm-5">
                <b-form-datepicker
                  id="inputbirthdate"
                  v-model="form.birthdate"
                  class="mb-2"
                  size="lg"
                  disabled
                ></b-form-datepicker>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputText05"
                class="col-md-2 col-form-label color-tosca"
                ><b>Jenis Kelamin</b></label
              >
              <div class="col-md-10 text-left">
                <div class="form-check form-check-inline">
                  <input
                    v-model="form.gender"
                    name="gender"
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio1"
                    value="Laki-laki"
                    disabled
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Laki-Laki</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="form.gender"
                    name="gender"
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    value="Perempuan"
                    disabled
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Perempuan</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="infocontent2">
          <div class="text-right">
            <b-button
              pill
              variant="info"
              type="submit"
              size="lg"
              style="padding-left: 30px; padding-right: 30px"
              >{{ submitLabel }}</b-button
            >
            <br />
            <small
              ><i class="text-danger">{{ isError }}</i></small
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegFormInternal",
  data() {
    return {
      id_reg: "",
      reglists: [],
      showSelectValidStudent: false,
      emptySelectValidStudent: "Belum ada daftar siswa yang dapat dipilih",
      showButtonGoToExternal: false,
      param_id_reg: "",
      param_id_reg_unitsort: "",
      showForm: false,
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      ppdbs: [],
      unitlevels: [],
      loading_ppdbs: "loading data...",
      loading_unitlevels: "silahkan pilih Sekolah Tujuan...",
      submitLabel: "DAFTAR",
      isError: "",
      loaderShow: false,
      form: {
        selected_id_reg: "",
        id_ppdb: "",
        id_unitlevel: "",
        birthplace: "",
        birthdate: "",
        fullname: "",
        nickname: "",
        gender: "",
      },
    };
  },
  created() {
    this.GetRegListValidIntRegistration();
    this.checkInternalRegWithRouteParam();
  },
  methods: {
    checkInternalRegWithRouteParam() {
      if (this.$route.params.id_reg != "") {
        this.param_id_reg = this.$route.params.id_reg;
        this.param_id_reg_unitsort = this.$route.params.unitsort;
        this.form.selected_id_reg = this.param_id_reg;
      }
    },
    async GetRegListValidIntRegistration() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/getreglistvalidintregistration",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.reglists = response.data.data;
        if (this.reglists.length == 0) {
          this.showSelectValidStudent = false;
          this.emptySelectValidStudent =
            "Belum ada daftar siswa yang dapat dipilih";
          this.showButtonGoToExternal = true;
        } else {
          this.showSelectValidStudent = true;
          this.emptySelectValidStudent = "";
          this.showButtonGoToExternal = false;
          this.checkInternalRegWithRouteParam();
        }
        console.log(this.reglists.length);
      } catch (error) {
        console.log(error);
      }
    },
    changeParamIDReg(event) {
      this.showForm = false;
      this.form.selected_id_reg = this.param_id_reg;

      var options = event.target.options;
      if (options.selectedIndex > -1) {
        this.param_id_reg_unitsort =
          options[options.selectedIndex].getAttribute("data-unitsort");
      }
    },
    validateStudent() {
      if (this.param_id_reg != "" && this.param_id_reg !== undefined) {
        this.showForm = true;

        this.getPPDBOpen();
        this.getDataReg();
      }
    },
    async getDataReg() {
      try {
        const response = await axios({
          method: "get",
          url: "api/getreg/" + this.form.selected_id_reg,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        // console.log(response.data.reg);
        this.form.fullname = response.data.reg.fullname;
        this.form.nickname = response.data.reg.nickname;
        this.form.birthplace = response.data.reg.birthplace;
        this.form.birthdate = response.data.reg.birthdate;
        this.form.gender = response.data.reg.gender;
      } catch (error) {
        console.log(error);
      }
    },
    async getPPDBOpen() {
      try {
        const response = await axios.get("/api/ppdb/internal");
        this.ppdbs = response.data;
        //console.log(this.ppdbs);

        this.loading_ppdbs = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getUnitLevels(idUnit) {
      try {
       
        let formData = new FormData();
        formData.append("idUnit[]", idUnit);
        
         
        await axios.post('/api/getunitlevels', formData)

        .then(response => {

            // console.log('output',response.data)
            //   console.log(response.data.data)
              this.unitlevels = response.data;

              // console.log('ini loh',this.unitlevels )

          // this.loading_unitlevels = "";
         

        }).catch(error => {

          console.log(error)

            //reject ke component dengan hasil response
        

        })



        // const response = await axios.get("/api/getunitlevels/data=" + idUnit);
        //this.unitlevels = response.data;
    
      } catch (error) {
        console.log(error);
      }
    },
    changeUnitLevels() {
      
      // var options = event.target.options;
      // console.log('opt', this.id_unit);

      // if (options.selectedIndex > -1) {
      //   this.id_unit =
      //     options[options.selectedIndex].getAttribute("data-id_unit");
      //   parseInt(this.id_unit);
      //   if (this.id_unit > 0) {
      //     //console.log(this.id_unit);
      //     // call function
      //     this.getUnitLevels(this.id_unit);
      //   }
      // }

      const selectedId = this.form.id_ppdb;

// Temukan objek ppdb berdasarkan id_ppdb yang dipilih
      const selectedPpdb = this.ppdbs.find(ppdb => ppdb.id === selectedId);
      // console.log("unitlevel",selectedPpdb.unitlevel);

      if (selectedPpdb) {
        // Set form.id_unitlevel ke nilai unitlevel dari ppdb yang dipilih
        this.form.id_unitlevel = selectedPpdb.unitlevel;
       
        this.getUnitLevels(this.form.id_unitlevel);
      }
    },
    async handleSubmit() {
      try {
        this.submitLabel = "processing...";
        const response = await axios({
          method: "post",
          url: "api/intregistration",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: this.form,
        });
        console.log(response.data);

        localStorage.setItem("id_reg", response.data.id_reg);
        this.submitLabel = "DAFTAR";
        // redirect
        this.$router.push({ path: "/successsubmitdatasendnotify" });
      } catch (error) {
        this.submitLabel = "DAFTAR";
        this.isError = error.response.data.message;
        console.log(error);
      }
    },
    GoToExternal() {
      this.loaderShow = true;
      window.location.href = "/parentguard/1";
    },
  },
};
</script>

<style>
</style>