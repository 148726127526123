<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline">
      <div style="padding: 40px">
        <h4 class="text-white text-center">FORMULIR PENDAFTARAN SISWA</h4>
        <div class="reg-container mt-4">
          <h5 class="text-success text-center">Step by Step Registration</h5>
          <br />
          <img
            alt="Reg Guide Internal"
            :src="ppdb_rule_image"
            class="img-fluid"
          />
          <br /><br />

          <div class="reginfo">
            <div class="infocontent">
              <b-row>
                <b-col cols="1"
                  ><b-icon
                    icon="calendar-check"
                    style="width: 50px; height: 50px"
                  ></b-icon
                ></b-col>
                <b-col cols="11">
                  <div class="text-left">
                    <h5>PANDUAN PENDAFTARAN</h5><br />
                    <span v-html="ppdb_rule"></span>
                  </div>
                  <br />
                  <div class="text-right">
                    <b-link class="btn btn-light" :to="{ path: '/reglist' }"
                      ><b-icon icon="arrow-left"></b-icon>{{ " " }}Batal</b-link
                    >{{ " " }}
                    <b-link
                      class="btn btn-dark btn-lg"
                      :to="{ path: '/parentguard' }"
                      >Pendaftaran Siswa</b-link
                    >
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
  </b-container>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "RegExternal",
  components: {
    DashboardHeader,
    HomeFooter,
  },
  data() {
    return {
      ppdb_rule: "",
      ppdb_rule_image: "",
      basePath: axios.defaults.baseURL,
      basePathFolderImageLogo: "/uploads/settingapps/",      
    }
  },
  created() {
    this.getPpdbRule();
  },
  methods: {
    async getPpdbRule() {
      try {
        const response = await axios.get("/api/settingapps/ppdb_rule/read", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data;
        this.ppdb_rule = this.datas.ppdb_rule;
        if(this.datas.ppdb_rule_image != '') {
          this.ppdb_rule_image = this.basePath+this.basePathFolderImageLogo+this.datas.ppdb_rule_image;
          console.log(this.ppdb_rule_image)
        }

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>