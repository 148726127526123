<template>
  <div class="reginfo2" v-if="showformregister">
    <div class="infotitle">
      <b-container>
        <b-row>
          <b-col cols="1"> </b-col>
          <b-col cols="11" class="text-left">
            <h4>IDENTITAS CALON SISWA</h4>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="infocontent2">
        <div style="max-width: 900px; margin: auto">
          <input type="hidden" v-model="form.id_user" />
          <input type="hidden" v-model="form.id_family" />
          <div class="mb-3 row form-input-label-right">
              <label
                for="inputText07"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tingkat Sekolah Tujuan</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_ppdb"
                  name="id_ppdb"
                  @change="changeUnitLevels"
                  class="form-control form-control-lg"
                  placeholder="Pilih Sekolah"
                  required
                >
                  <option
                    v-for="ppdb in ppdbs"
                    v-bind:key="ppdb.id"
                    v-bind:value="ppdb.id"
                    :data-id_unit="ppdb.id_unit"
                    :data-type="ppdb.type"
                  >
                  <!--  v-if="ppdb.unitsort > param_id_reg_unitsort" -->
                    <div>
                      {{ ppdb.desc }} - ({{ ppdb.type }})
                    </div>
                  </option>
                </select>
                <small
                  ><i class="text-muted">{{ loading_ppdbs }}</i></small
                >
              </div>
            </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputText07"
              class="col-md-2 col-form-label color-tosca"
              ><b>Kelas Tujuan </b></label
            >
            <div class="col-md-10">
              <b-row>
                <b-col>
                  <select
                    v-model="form.id_unitlevel"
                    @change="setupValidateUnitLevels"
                    name="id_unitlevel"
                    class="form-control form-control-lg"
                    aria-label="Default select example"
                    required
                  >
                    <option
                      v-for="unitlevel in unitlevels"
                      v-bind:key="unitlevel.id"
                      v-bind:value="unitlevel.id"
                      :data-validate_min_age="unitlevel.validate_min_age"
                      :data-validate_max_month="unitlevel.validate_max_month"
                      :data-validate_max_date="unitlevel.validate_max_date"
                      :data-validate_max_year="unitlevel.validate_max_year"
                    >
                      {{ unitlevel.name }}
                    </option>
                  </select>
                  <small
                    ><i class="text-muted">{{ loading_unitlevels }}</i></small
                  >
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- <div class="mb-3 row form-input-label-right">
            <label for="inputText07" class="col-md-2 col-form-label color-tosca"
              ><b>Kelas Tujuan</b></label
            >
            <div class="col-md-10">
              <b-row>
                <b-col>
                  <span v-if="disabledSelectUnitLevel">
                    <select class="form-control form-control-lg" disabled>
                    </select>
                  </span>
                  <span v-if="showSelectUnitLevel">
                    <select
                      v-model="form.id_unitlevel"
                      name="id_unitlevel"
                      class="form-control form-control-lg"
                      aria-label="Default select example"
                      @change="setupValidateUnitLevels"
                      required
                    >
                      <option
                        v-for="unitlevel in unitlevels"
                        v-bind:key="unitlevel.id"
                        v-bind:value="unitlevel.id"
                        :data-validate_min_age="unitlevel.validate_min_age"
                        :data-validate_max_month="unitlevel.validate_max_month"
                        :data-validate_max_date="unitlevel.validate_max_date"
                        :data-validate_max_year="unitlevel.validate_max_year"
                      >
                        {{ unitlevel.name }}
                      </option>
                    </select>
                  </span>
                  <small
                    ><i class="text-muted">{{ loading_unitlevels }}</i></small
                  >
                </b-col>
              </b-row>
            </div>
          </div> -->

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputbirthdate"
              class="col-md-2 col-form-label color-tosca"
              ><b>Tanggal Lahir</b></label
            >
            <div class="col-sm-5">
              <div class="input-group">
                <flat-pickr
                  v-model="form.birthdate"
                  :config="config"
                  class="form-control form-control-lg"
                  placeholder="Select date"
                  name="date"
                >
                </flat-pickr>
                <div class="input-group-btn">
                  <button
                    class="btn btn-lg btn-dark"
                    type="button"
                    title="Toggle"
                    data-toggle
                  >
                    <b-icon icon="calendar3"></b-icon>
                  </button>
                </div>
              </div>
              <!--
              <b-form-datepicker
                id="inputbirthdate"
                v-model="form.birthdate"
                class="mb-2"
                size="lg"
                :min="min"
                :max="max"
                required
              ></b-form-datepicker>
              -->
              <div
                class="text-left"
                v-if="validate_birthdate"
                style="line-height: 1"
              >
             
                <small class="text-muted">
                  PPDB Type: {{ ppdb_type }}<br />
                  Sudah berumur <b>{{ validate_min_age }} tahun</b> pada tahun
                  <b>{{ initValidateCalculateBirthdate }}</b
                  ><br />
                  Rekomendasi batas tanggal lahir
                  <b>{{ initValidateRecommendedBirthdate }}</b>
                </small>
              </div>
            </div>
          </div>
          <hr />
          <div class="mb-3 row form-input-label-right">
            <label
              for="inputbirthplace"
              class="col-md-2 col-form-label color-tosca"
              ><b>Tempat Lahir</b></label
            >
            <div class="col-md-10">
              <input
                type="text"
                v-model="form.birthplace"
                name="birthplace"
                class="form-control form-control-lg"
                id="inputbirthplace"
                required
              />
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputfullname"
              class="col-md-2 col-form-label color-tosca"
              ><b>Nama Lengkap</b></label
            >
            <div class="col-md-10">
              <input
                type="text"
                v-model="form.fullname"
                name="fullname"
                class="form-control form-control-lg"
                id="inputfullname"
                required
              />
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label
              for="inputnickname"
              class="col-md-2 col-form-label color-tosca"
              ><b>Nama Panggilan</b></label
            >
            <div class="col-md-10">
              <input
                type="text"
                v-model="form.nickname"
                name="nickname"
                class="form-control form-control-lg"
                id="inputnickname"
                required
              />
            </div>
          </div>

          <div v-if="hidedata">
            <div class="mb-3 row form-input-label-right">
              <label for="inputText06" class="col-md-2 col-form-label color-tosca"
                ><b>Asal Sekolah</b></label
              >
              <div class="col-md-10">
                <b-row>
                  <b-col>
                    <select
                      v-model="form.id_sch"
                      name="id_sch"
                      class="form-control form-control-lg"
                      aria-label="Default select example"
                    >
                      <option
                        v-for="sch in schs"
                        v-bind:key="sch.id"
                        v-bind:value="sch.id"
                      >
                        {{ sch.name }}
                      </option>

                      <option value="">(Belum ada sekolah)</option>
                    </select>
                    <small
                      ><i class="text-muted">{{ loading_schs }}</i></small
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>

          <div class="mb-3 row form-input-label-right">
            <label for="inputText05" class="col-md-2 col-form-label color-tosca"
              ><b>Jenis Kelamin</b></label
            >
            <div class="col-md-10 text-left">
              <div class="form-check form-check-inline">
                <input
                  v-model="form.gender"
                  name="gender"
                  class="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  value="Laki-laki"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Laki-Laki</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="form.gender"
                  name="gender"
                  class="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  value="Perempuan"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >Perempuan</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="infocontent2">
        <div class="text-right">
          <b-button
            pill
            variant="outline-secondary"
            :to="{ path: '/reglist' }"
            type="link"
            style="padding-left: 30px; padding-right: 30px"
            >Batal</b-button
          >{{ " " }}
          <b-button
            pill
            variant="info"
            type="submit"
            size="lg"
            style="padding-left: 30px; padding-right: 30px"
            >{{ submitLabel }}</b-button
          >
          <br />
          <small
            ><i class="text-danger">{{ isError }}</i></small
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "RegFormExternal",
  components: {
    flatPickr,
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        minDate: "",
        maxDate: "",
      },

      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      ppdbs: [],
      schs: [],
      tas: [],
      unitlevels: [],

      submitLabel: "DAFTAR",
      loading_ppdbs: "loading data...",
      loading_schs: "loading data...",
      loading_tas: "loading data...",
      loading_unitlevels: "silahkan pilih Sekolah Tujuan terlebih dahulu...",

      isError: "",

      showSelectUnitLevel: false,
      disabledSelectUnitLevel: true,

      // validate birthdate
      ppdb_type: "",
      validate_birthdate: false,
      validate_max_month: "",
      validate_max_date: "",
      validate_max_year: "",
      validate_min_age: "0",
      initValidateCalculateBirthdate: "0",
      initValidateRecommendedBirthdate: "0",
      min: "",
      max: "",

      form: {
        id_unit: "",
        id_ppdb: "",
        id_unitlevel: "",
        birthplace: "",
        birthdate: "",
        fullname: "",
        nickname: "",
        id_sch: "",
        gender: "",
        startdate: "",
        id_user: "",
        id_family: "",
      },
      checkedform: "",
      showformregister: true,

      hidedata: false,
    };
  },
  created() {
    this.getIdUser();
    this.getPPDBOpen();
    this.getSchOrigin();
    this.getTaOpenReg();
  },
  methods: {
    getIdUser() {
      let dataAuth = JSON.parse(this.auth);
      this.form.id_user = dataAuth.id_user;
      this.form.id_family = dataAuth.id_family;
    },
    async getPPDBOpen() {
      try {
        const response = await axios.get("/api/ppdb/external");
        this.ppdbs = response.data;
        //console.log(this.ppdbs);
        if(this.ppdbs.length == 0) {
          this.showformregister = false;
        }

        this.loading_ppdbs = "";
      } catch (error) {
        console.log(error);
      }
    },

    async getSchOrigin() {
      try {
        const response = await axios.get("/api/sch");
        this.schs = response.data;
        //console.log(this.schs);

        this.loading_schs = "";
      } catch (error) {
        console.log(error);
      }
    },

    async getTaOpenReg() {
      try {
        const response = await axios.get("/api/taopenreg");
        this.tas = response.data;
        //console.log(this.tas);

        this.loading_tas = "";
      } catch (error) {
        console.log(error);
      }
    },

    async getUnitLevels(idUnit) {
      try {
       
        let formData = new FormData();
        formData.append('type', this.ppdb_type)
        formData.append("idUnit[]", idUnit);
        
         
        await axios.post('/api/getunitlevels', formData)

        .then(response => {

            // console.log('output',response.data)
            //   console.log(response.data.data)
              this.unitlevels = response.data;

              // console.log('ini loh',this.unitlevels )

          // this.loading_unitlevels = "";
         

        }).catch(error => {

          console.log(error)

            //reject ke component dengan hasil response
        

        })



        // const response = await axios.get("/api/getunitlevels/data=" + idUnit);
        //this.unitlevels = response.data;
    
      } catch (error) {
        console.log(error);
      }
    },

    // async getUnitLevels(idUnit,ppdb_type,exclude_last_unitlevel) {
    //   this.disabledSelectUnitLevel = true;
    //   this.loading_unitlevels = "Loading...";
    //   try {
    //     const response = await axios.get("/api/getunitlevels/" + idUnit + "?type=" + ppdb_type);
    //     this.unitlevels = response.data;
    //     //let totalLength = this.unitlevels.length;
    //     //console.log(this.unitlevels);
    //     // if(ppdb_type == 'Baru') {
    //     //   this.unitlevels = [response.data[0]];
    //     // }

    //     if(exclude_last_unitlevel == 1) {
    //       //let showMax = totalLength - 1;
    //       //console.log('filter unitlevel '+totalLength+' '+showMax)

    //       // remove last object
    //       this.unitlevels.pop();
    //       //console.log(this.unitlevels);
    //     }

    //     this.loading_unitlevels = "";
    //     this.disabledSelectUnitLevel = false;
    //     this.showSelectUnitLevel = true;
    //   } catch (error) {
    //     console.log(error);
    //     this.disabledSelectUnitLevel = true;
    //     this.loading_unitlevels = "";        
    //   }
    // },


    // changeUnitLevels(event) {
    //   var options = event.target.options;
    //   if (options.selectedIndex > -1) {
    //     this.id_unit = options[options.selectedIndex].getAttribute("data-id_unit");
    //     this.ppdb_type = options[options.selectedIndex].getAttribute("data-type");
    //     let exclude_last_unitlevel = options[options.selectedIndex].getAttribute("data-exclude_last_unitlevel");
    //     //console.log('exclude_last_unitlevel: '+exclude_last_unitlevel)
    //     // set default
    //     this.disabledValidateBirthdate();

    //     parseInt(this.id_unit);
    //     if (this.id_unit > 0) {
    //       //console.log(this.id_unit);
    //       // call function
    //       this.getUnitLevels(this.id_unit,this.ppdb_type,exclude_last_unitlevel);
    //     }
    //   }
    // },
    setupValidateUnitLevels(event) {
      // console.log(this.ppdb_type);
      var options = event.target;
      //console.log(event.target)
      if (options.selectedIndex > -1) {
        this.validate_min_age = options[options.selectedIndex].getAttribute(
          "data-validate_min_age"
        );
        
        this.validate_max_month = options[options.selectedIndex].getAttribute(
          "data-validate_max_month"
        );
        this.validate_max_date = options[options.selectedIndex].getAttribute(
          "data-validate_max_date"
        );
        this.validate_max_year = options[options.selectedIndex].getAttribute(
          "data-validate_max_year"
        );
        // console.log(this.validate_min_age+' '+this.validate_max_month+' '+this.validate_max_year+' '+ this.validate_max_date);
        if (
          this.validate_min_age === null ||
          this.validate_max_month === null ||
          this.validate_max_date === null ||
          this.validate_max_year === null
        ) 
        {
          // console.log('data koson',this.validate_min_age+' '+this.validate_max_month+' '+this.validate_max_year+' '+ this.validate_max_date);
          this.disabledValidateBirthdate();
        } else {
          // console.log('data ada',this.validate_min_age+' '+this.validate_max_month+' '+this.validate_max_year+' '+ this.validate_max_date);
          // this.activeValidateBirthdate();
          // this.disabledValidateBirthdate();
          if (this.ppdb_type == "Baru") {
            this.activeValidateBirthdate();
          } else {
            this.disabledValidateBirthdate();
          }
        }
      }
    },
    activeValidateBirthdate() {
      this.validate_birthdate = true;
      // console.log('va;idasi',this.validate_birthdate);
      this.form.birthdate = "";

      const now = new Date();
      let year = now.getFullYear();
      let month = this.validate_max_month;
      // console.log('bulan',month);
      let date = this.validate_max_date;
      if (month.length == 1) {
        month = "0" + month;
      }
      if (date.length == 1) {
        date = "0" + date;
      }
      if (this.validate_max_year == "next_year") {
        year = year + 1;
      }
      parseInt(year);
      let initValidAge = parseInt(this.validate_min_age);
      this.initValidateCalculateBirthdate = date + "-" + month + "-" + year;
      this.initValidateRecommendedBirthdate =
        date + "-" + month + "-" + (year - initValidAge);
      this.max = year - initValidAge + "-" + month + "-" + date;
      this.config.maxDate = year - initValidAge + "-" + month + "-" + date;
    },
    disabledValidateBirthdate() {
      this.validate_birthdate = false;
      this.validate_min_age = "0";
      this.initValidateCalculateBirthdate = "0";
      this.initValidateRecommendedBirthdate = "0";
      this.id_unitlevel = "";

      this.form.birthdate = "";
      this.validate_min_age = "";
      this.validate_max_month = "";
      this.validate_max_date = "";
      this.validate_max_year = "";
      this.min = "";
      this.config.minDate = "";
      this.max = "";
      this.config.maxDate = "";
    },
    changeUnitLevels(event) {
      
      // var options = event.target.options;
      // console.log('opt', this.id_unit);

      // if (options.selectedIndex > -1) {
      //   this.id_unit =
      //     options[options.selectedIndex].getAttribute("data-id_unit");
      //   parseInt(this.id_unit);
      //   if (this.id_unit > 0) {
      //     //console.log(this.id_unit);
      //     // call function
      //     this.getUnitLevels(this.id_unit);
      //   }
      // }

      var options = event.target;
      // console.log('changeunit',options)
      const selectedId = this.form.id_ppdb;

      this.ppdb_type = options[options.selectedIndex].getAttribute("data-type")
     

    // Temukan objek ppdb berdasarkan id_ppdb yang dipilih
      const selectedPpdb = this.ppdbs.find(ppdb => ppdb.id === selectedId);
      // console.log("unitlevel",selectedPpdb.unitlevel);
      //  console.log("unitlevel",selectedPpdb);

      if (selectedPpdb) {
        // Set form.id_unitlevel ke nilai unitlevel dari ppdb yang dipilih
        this.form.id_unitlevel = selectedPpdb.unitlevel;
        // this.disabledValidateBirthdate();
       
        this.getUnitLevels(this.form.id_unitlevel, this.ppdb_type);
      }
    },
    async handleSubmit() {
      try {
        this.submitLabel = "processing...";
        const response = await axios({
          method: "post",
          url: "api/extregistration",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: this.form,
        });

        localStorage.setItem("id_reg", response.data.id_reg);
        //console.log(response);
        // redirect
        this.$router.push({ path: "/successsubmitdatasendnotify" });
      } catch (error) {
        this.submitLabel = "DAFTAR";
        this.isError = error.response.data.message;
        //console.log(error);
      }
    },
  },
};
</script>

<style>
</style>