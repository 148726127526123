<template>
  <div>
    <Auth />
    <div
      class="bg-white"
      style="
        padding-top: 20px;
        padding-left: 40px;
        padding-bottom: 100px;
        padding-right: 40px;
      "
    >
      <b-row>
        <b-col lg="3" md="3" sm="12" xs="12" class="text-left">
          <div class="logo">
            <b-link :to="{ path: '/dashboard' }">
              <img :alt="altlogo" :src="logo" height="60" />
            </b-link>
          </div>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <div class="text-center">
            <small class="font-weight-bold text-success">{{
              themesapp.tagline
            }}</small>
          </div>
          <b-row class="mt-4">
            <b-col>
              <table>
                <tr>
                  <td width="50" class="text-center">
                    <img
                      alt="Vue logo"
                      src="../assets/img/whatsapp.svg"
                      width="30"
                    />
                  </td>
                  <td style="line-height: 1">
                    <small class="text-success">
                      Whatsapps<br />
                      <b>{{ themesapp.wa_number }}</b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>

            <b-col>
              <table>
                <tr>
                  <td width="50" class="text-center">
                    <img
                      alt="Vue logo"
                      src="../assets/img/telephone.svg"
                      width="25"
                    />
                  </td>
                  <td style="line-height: 1">
                    <small class="text-success">
                      <!-- Telepon<br /> -->
                      <b>{{ themesapp.phone }}</b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>

            <b-col>
              <table>
                <tr>
                  <td width="50" class="text-center">
                    <img
                      alt="Vue logo"
                      src="../assets/img/map.svg"
                      width="30"
                    />
                  </td>
                  <td style="line-height: 1">
                    <small class="text-success">
                      <!-- Visit Us<br /> -->
                      <b>{{ themesapp.address }}</b>
                    </small>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" md="3" sm="12" xs="12">
          <br />
          <table width="100%" border="0">
            <tr>
              <td class="text-right td-button-log">
                <span v-if="buttonLogin">
                  <b-button
                    pill
                    variant="info"
                    :to="{ path: '/login' }"
                    size="md"
                    style="padding-left: 30px; padding-right: 30px"
                    >Login</b-button
                  >
                </span>
                <span v-if="buttonLogout">
                  <b-button
                    pill
                    variant="danger"
                    :to="{ path: '/logout' }"
                    size="md"
                    style="padding-left: 30px; padding-right: 30px"
                    >Logout</b-button
                  >
                </span>
              </td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth/Auth.vue";
import axios from "axios";

export default {
  name: "DashboardHeader",
  components: {
    Auth,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      buttonLogin: true,
      buttonLogout: false,
      themesapp: [],
      basePath: axios.defaults.baseURL,
      logo: "",
      altlogo: "",
    };
  },
  created() {
    this.getLoginLogout();
    this.getThemeApp();
  },
  methods: {
    getLoginLogout() {
      let curRoute = this.$router.currentRoute.path;
      let logged = this.$store.getters.logged;
      //console.log(curRoute + " " + logged);
      if (
        curRoute !== "/login" &&
        curRoute !== "/register" &&
        curRoute !== "/logout"
      ) {
        if (this.token != "" || logged == true) {
          this.buttonLogin = false;
          this.buttonLogout = true;
        }
      } else {
        this.buttonLogin = true;
        this.buttonLogout = false;
      }
    },
    getThemeApp() {
      this.themesapp = this.$store.getters.themesapp;
      this.altlogo = this.themesapp.name;
      this.logo = this.basePath + "/uploads/settingapps/" + this.themesapp.logo;
    },
  },
};
</script>

<style>
</style>