<template>
  <div class="banner-headline text-center">
      <h1 class="color-yellow">PPSB 2021</h1>
      <h2>SEKOLAH ISLAM AN-NISAA IZADA</h2>
      <h3 class="font-weight-normal">PENERIMAAN PESERTA SISWA BARU<br/>TAHUN PELAJARAN 2022/2033</h3>
      <br />
      <b-button variant="outline-light" :to="{ path: '/reginternal' }" class="shadow" size="lg">INTERNAL</b-button>&nbsp;&nbsp;&nbsp;
      <b-button variant="primary" :to="{ path: '/regexternal' }" class="shadow" size="lg">EKSTERNAL</b-button>
      <br /><br />
              <img
          alt="Instrument"
          src="../assets/img/instrument.png" class="img-fluid"
        />

  </div>
</template>

<script>
export default {
    name: "BannerHeadline",
}
</script>

<style>

</style>