<template>
  <div class="text-dark text-left">
    <div class="reginfo2">
      <div class="infocontent shadow rounded">
        <div v-if="showEmptyTable">
          <div class="showemptytableinfo">
            <h4>
              Anda belum memiliki anak yang didaftarkan<br />
              <small>Silahkan daftarkan anak Anda</small>
              <br /><br />
              <b-button
                size="lg"
                variant="dark"
                :to="{ path: '/regguide' }"
                class="text-white"
                >klik disini untuk Daftar Baru</b-button
              >
            </h4>
          </div>
        </div>
        <div v-if="showTable">
          <b-tabs
            fill
            active-nav-item-class="text-white font-weight-bold"
            v-model="tabIndex"
          >
            <div v-for="(datatab, index) in datatabs" :key="datatab.id_unit">
              <b-tab :title="datatab.label" :title-link-class="linkClass(index)">
                <div class="table-responsive" :data-index="index">
                  <table class="table">
                    <thead class="thead-reginfo">
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama Anak</th>
                        <th scope="col">Status</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody class="tbody-reginfo">
                      <tr v-for="(data, index2) in datatab.data" :key="data.id">
                        <td>{{ index2 + 1 }}.</td>
                        <td>
                          <b>{{ data.fullname }} </b
                          ><br />
                          No. Reg: {{ data.noreg }}<br />
                          Status: <b>{{ data.status === 'Registered' ? 'Terdaftar' : data.status === 'Active' ? 'Aktif' : data.status === 'Passed' ? 'Lulus' : data.status === 'Conditional Active Student' ? 'Lulus Bersyarat' : data.status === 'Backup Active Student' ? 'Lulus Cadangan' : data.status === 'Fail' ? 'Tidak Lulus' : data.status === 'Active Student' ? 'Siswa Aktif' : '-' }}</b><br />
                          Level: {{ data.unitlevel_name }}<br />                          
                          <!-- <span v-if="data.student_active.classroom != NULL">Kelas: {{ data.student_active.classroom.classname }}<br /></span> -->
                          <div v-if="addinfo">
                            Info Kelulusan: {{ data.display_passdate }}<br />
                            Rencana Mulai Belajar: {{ data.display_startdate
                            }}<br />
                          </div>
                          <div
                            class="mt-2"
                            v-if="
                              data.status == 'Active' ||
                              data.status == 'Passed' ||
                              data.status == 'Active Student' ||
                              data.status == 'Conditional Active Student' ||
                              data.status == 'Backup Active Student'
                            "
                          >
                          <!--  <span v-if="data.student_active.nis != NULL">NIS: {{ data.student_active.nis }}</span> -->
                          <br /><br />
                            <b-button-group>
                              <b-button
                                variant="warning"
                                class="text-white"
                                :to="{
                                  name: 'GoToChild',
                                  params: {
                                    id_reg: data.id,
                                    noreg: data.noreg,
                                    format_birthdate: data.format_birthdate,
                                  },
                                }"
                                size="sm"
                                >Update Data</b-button
                              >
                            </b-button-group>
                          </div>
                        </td>
                        <td>
                          <!-- <div
                            class="mt-2"
                            v-if="
                              data.status == 'Registered' ||
                              data.status == 'Active' ||
                              data.status == 'Passed'
                            "
                          > -->
                            <!-- <pre class="text-white">{{ data.paidlist }}</pre>
                            <b>[{{ data.paidlist_status }}]</b> -->
                          <!-- </div> -->

                          <!-- <br /> -->
                          <div>
                            <small>Kelengkapan Biodata:</small><br />
                            <span
                              v-for="mandatory in data.mandatory_data"
                              v-bind:key="mandatory.nik"
                            >
                              <span v-if="mandatory['value'] == true"
                                ><b-badge variant="success">{{
                                  mandatory["label"]
                                }}</b-badge
                                >&nbsp;<b-icon-check></b-icon-check></span
                              ><span v-else
                                ><b-badge
                                  variant="danger"
                                  v-b-tooltip.hover
                                  title="Mohon dilengkapi"
                                  >{{ mandatory["label"] }}</b-badge
                                >&nbsp;<b-icon-exclamation-circle
                                ></b-icon-exclamation-circle></span
                              ><br />
                            </span>
                          </div>
                         
                        </td>
                        <td width="400px">
                          <div v-if="data.status == 'Registered'">
                            Ananda sudah terdaftar sebagai calon siswa Sekolah An-Nisaa Izada.<br /> <br />
                            Silahkan melakukan Pembayaran adminstrasi PSB.
                          </div>
                          <div v-if="data.status == 'Active'">
                           Ananda  telah aktif sebagai calon siswa  di Sekolah An-Nisaa Izada, <br />
                            Silakan klik tombol <strong>Update</strong> untuk melengkapi data.
                            <ol>
                            <li>Lengkapi biodata siswa, pendidikan, data orang tua (ayah dan ibu), 
                            tempat tinggal, hingga prestasi dan kesehatan.</li> 
                            <li>Jika sudah selesai, lanjutkan dengan <strong>Upload dokumen</strong> yang dibutuhkan seperti foto siswa, akte lahir, serta kartu keluarga.</li> 
                            <li>Download Surat Pernyataan Orang Tua melalui menu Print Pernyataan Orang Tua , hasil download dicetak dan ditandatangani diatas materai serta diupload kembali melalui menu Upload Dokumen</li>
                            </ol>
                          </div>
                          <div v-if="data.status == 'Passed'">
                            Alhamdulillah, ananda dinyatakan <strong>LULUS</strong> di Sekolah An-Nisaa’ Izada. <br /><br />Informasi lebih lanjut akan disampaikan menyusul oleh tim PSB
                          </div>
                          <div v-if="data.status == 'Active Student'">
                             <span v-if="data.student_active.nis != NULL">Nomor Induk Siswa : {{ data.student_active.nis }}<br /></span>
                            <span v-else>
                            Siswa telah dinyatakan Aktif, selanjutnya menunggu
                            pembuatan Nomor Induk Siswa (NIS)<br />
                            </span>
                            <span v-if="data.student_active.classroom != NULL">Kelas: {{ data.student_active.classroom.classname }}<br /></span>
                            <span v-else>
                            Siswa telah dinyatakan sebagai Siswa Aktif, selanjutnya menunggu
                            pembuatan Pengaturan Kelas<br />
                            </span>
                            <br />
                            <div
                            v-if="
                              data.student_active.internal_registration == true
                            "
                          >                            
                            <b-button
                              variant="dark"
                              :to="{
                                name: 'Parentguard',
                                params: {
                                  id_reg: data.id,
                                  unitsort: data.unitsort,
                                },
                              }"
                              class="text-white text-left"                              
                              >Siswa Internal,  Klik disini <br />untuk mendaftar kejenjang berikutnya</b-button
                            >
                          </div>
                          </div>
                          <div
                            v-if="data.status == 'Conditional Active Student'"
                          >
                              Terimakasih, ananda telah mengikuti proses penerimaan siswa baru Sekolah An-Nisaa izada, 
                          untuk selanjutnya kami akan mengundang Bapak/Ibu untuk diskusi mengenai hasilnya. 
                          Waktu dan tempat akan diinformasikan menyusul oleh Tim PSB 
                          </div>
                          <div v-if="data.status == 'Backup Active Student'">
                             Terimakasih, ananda telah mengikuti proses penerimaan siswa baru Sekolah An-Nisaa izada 
                            dan dinyatakan LULUS sebagai CADANGAN.
                          </div>

                          <div v-if="data.status == 'Fail'">
                            Mohon maaf ananda belum dapat bergabung dengan Sekolah An-Nisaa Izada. 
                            Waktu pengambilan hasil seleksi dan psikotes akan diinformasikan menyusul.
                          </div>

                          <div v-if="data.status == 'Cancel Register' || data.status == 'Cancel Aktif'">
                            Proses PSB dibatalkan, silakan menghubungi panitia PSB
                          </div>

                          <!--
                          <b-button-group>
                            <b-button variant="danger" disabled size="sm"
                              >Akademik</b-button
                            >
                            <b-button variant="primary" disabled size="sm"
                              >Forum</b-button
                            >
                          </b-button-group>
                          -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </div>
          </b-tabs>

          <br />
          <hr />
          <br />
          <div class="text-center">
            <b-button
              variant="dark"
              :to="{ path: '/regguide' }"
              class="text-white"
              >Klik disini untuk Daftar Baru</b-button
            >
          </div>
        </div>
        <div class="text-center mt-3">
          <div v-if="loadingReglist">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegListsTable",
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      fullname: "",
      id_user: "",
      units: [],
      datatabs: [],
      loadingReglist: true,
      showTable: false,
      showEmptyTable: false,
      addinfo: false,
      tabIndex: 0,
    };
  },
  created() {
    this.getDefaultVar();
    this.getRegList();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-white", "text-dark"];
      } else {
        return ["bg-secondary", "text-white"];
      }
    },
    async getRegList() {
      this.loadingReglist = true;
      try {
        const response = await axios.get("/api/getreglist", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);

        this.datatabs = response.data.tabs2;
        if (this.datatabs.length > 0) {
          this.showTable = true;
          this.showEmptyTable = false;
        } else {
          this.showTable = false;
          this.showEmptyTable = true;
        }
        this.loadingReglist = false;
      } catch (error) {
        console.log(error);
        this.loadingReglist = true;
      }
    },
  },
};
</script>

<style>
</style>