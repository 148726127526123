<template>
  <div>
    <div class="reginfo2">
      <div class="infotitle">
        <b-container>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col cols="11" class="text-left">
              <h4>KETERANGAN PENDIDIKAN</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <form @submit.prevent="handleSubmit">
        <input type="hidden" v-model="form.id" />
        <input type="hidden" v-model="form.id_reg" />
        <input type="hidden" v-model="form.id_user" />

        <div class="infocontent2">
          <div style="max-width: 900px; margin: auto">
            <p class="text-muted">
              Silahkan diisi form ini, jika memiliki riwayat pendidikan
              sebelumnya.
            </p>
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputid_sch"
                class="col-md-2 col-form-label color-tosca"
                ><b>Asal Sekolah</b></label
              >
              <div class="col-md-10">
                <select
                  v-model="form.id_sch"
                  name="id_sch"
                  class="form-control form-control-lg"
                  aria-label="Default select example"
                  id="inputid_sch"
                >
                  <option
                    v-for="sch in schs"
                    v-bind:key="sch.id"
                    v-bind:value="sch.id"
                  >
                    {{ sch.name }}
                  </option>
                  <option value="">(Belum ada sekolah)</option>
                </select>
                
                <span class="text-muted"
                  >Tidak menemukan data sekolah Anda?,
                  <b-link v-b-modal.modal-1
                    >klik disini untuk tambah baru.</b-link
                  ></span
                >
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputNisn" class="col-md-2 col-form-label color-tosca"
                ><b>NISN</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.nisn"
                  name="nisn"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputNisn"
                />
              </div>
            </div>

            <div v-if="addeduinfo">
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputYear_graduation"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tahun Lulus</b></label
                >
                <div class="col-md-5">
                  <input
                    v-model="form.year_graduation"
                    name="year_graduation"
                    type="year"
                    class="form-control form-control-lg"
                    id="Year_graduation"
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputCertificate_date"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tanggal Ijasah</b></label
                >
                <div class="col-md-5">
                  <b-form-datepicker
                    id="inputCertificate_date"
                    v-model="form.certificate_date"
                    name="certificate_date"
                    class="mb-2"
                    size="lg"
                    required
                  ></b-form-datepicker>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputCertificate_number"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Nomor Ijasah</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.certificate_number"
                    name="certificate_number"
                    type="text"
                    class="form-control form-control-lg"
                    id="inputCertificate_number"
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputUn_number"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Nomor UN</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.un_number"
                    name="un_number"
                    type="text"
                    class="form-control form-control-lg"
                    id="inputUn_number"
                  />
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputUn_date"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Tanggal UN</b></label
                >
                <div class="col-md-5">
                  <b-form-datepicker
                    id="inputUn_date"
                    v-model="form.un_date"
                    name="un_date"
                    class="mb-2"
                    size="lg"
                    required
                  ></b-form-datepicker>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputSkhun"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Nomor SKHUN</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.skhun"
                    name="skhun"
                    type="string"
                    class="form-control form-control-lg"
                    id="inputSkhun"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="infotitle">
            <b-container>
              <b-row>
                <b-col cols="1"> </b-col>
                <b-col cols="11" class="text-left">
                  <h4>ALASAN KEPINDAHAN</h4>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="infocontent2">
            <div style="max-width: 900px; margin: auto">
              <p class="text-muted">
                Silahkan diisi keterangan dibawah ini, jika anak Anda merupakan
                siswa pindahan dari sekolah lain.
              </p>
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputid_sch"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Kelas yang Ditinggalkan</b></label
                >
                <div class="col-md-10">
                  <input
                    v-model="form.abandoned_classes"
                    type="text"
                    class="form-control form-control-lg"
                  />
                </div>
              </div>
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputid_sch"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Alasan Kepindahan</b></label
                >
                <div class="col-md-10">
                  <textarea
                    v-model="form.reason_for_the_move"
                    type="text"
                    rows="6"
                    class="form-control form-control-lg"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="text-right">
            <button class="btn btn-info btn-lg" type="submit">
              {{ displayLoadingSubmit }}
            </button>
            <br />
            <br />
            <small
              ><i class="text-danger">{{ displayError }}</i></small
            >
            <i class="text-success">{{ displaySuccess }}</i>
          </div>
        </div>
      </form>
      <b-modal
        id="modal-1"
        centered
        title="Tambahkan Data Sekolah Baru"
        ok-only
        ok-title="Cancel"
        ok-variant="secondary"
      >
        <form @submit.prevent="handleAddSches">
          <label class="col-md-12 col-form-label form-control-label color-tosca"
            ><b>Status Sekolah</b></label
          >
          <div class="col-md-12">
            <select
              v-model="formsches.status"
              class="form-control form-control-lg"
            >
              <option
                v-for="select in selects.schstatus"
                v-bind:key="select.value"
                v-bind:value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <label class="col-md-12 col-form-label form-control-label color-tosca"
            ><b>Nama Sekolah</b></label
          >
          <div class="col-md-12">
            <input
              v-model="formsches.name"
              type="text"
              class="form-control form-control-lg"
            />
          </div>
          <label class="col-md-12 col-form-label form-control-label color-tosca"
            ><b>Provinsi</b></label
          >
          <div class="col-md-12">
            <select
              v-model="formsches.id_province"
              class="form-control form-control-lg"
              @change="handleSelectCity"
            >
              <option
                v-for="select in selects.provinces"
                v-bind:key="select.value"
                v-bind:value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>

          <label class="col-md-12 col-form-label form-control-label color-tosca"
            ><b>Kabupaten/Kota</b></label
          >
          <div class="col-md-12">
            <select
              v-model="formsches.id_city"
              class="form-control form-control-lg"
            >
              <option
                v-for="select in selects.cities"
                v-bind:key="select.value"
                v-bind:value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <br />
          <div class="text-left">
            <div class="col-md-12">
              <button class="btn btn-info" type="submit">
                {{ displayLoadingSubmit2 }}
              </button>
              <br />
              <br />
              <small
                ><i class="text-danger">{{ displayError2 }}</i></small
              >
              <i class="text-success">{{ displaySuccess2 }}</i>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "EducationProfileStudent",
  data() {
    return {
      token: localStorage.getItem("token"),
      auth: localStorage.getItem("auth"),
      form: {
        id: "",
        id_user: "",
        id_reg: "",
        id_sch: "",
        nisn: "",
        year_graduation: "",
        certificate_date: "",
        certificate_number: "",
        un_number: "",
        un_date: "",
        skhun: "",
        abandoned_classes: "",
        reason_for_the_move: "",
      },
      formsches: {
        status: "",
        name: "",
        id_province: "",
        id_city: "",
      },
      selects: {
        provinces: [],
        cities: [],
        schstatus: [
          {
            value: "Swasta",
            name: "Swasta",
          },
          {
            value: "Negeri",
            name: "Negeri",
          },
        ],
      },
      reg: [],
      eduinfo: [],
      schs: [],
      displayLoadingSubmit: "Simpan Data",
      displayLoadingSubmit2: "Simpan Data",
      displayError: "",
      displayError2: "",
      displaySuccess: "",
      displaySuccess2: "",
      addeduinfo: false,
    };
  },
  created() {
    this.defaultVar();
    this.getEduInfo();
    this.getSchOrigin();
    this.getProvinceSelect();
  },
  methods: {
    defaultVar() {
      this.dataAuth = JSON.parse(this.auth);
      this.form.id_user = this.dataAuth.id_user;
      this.form.id_reg = this.dataAuth.id_reg;
    },
    async getProvinceSelect() {
      try {
        const response = await axios.get("api/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.provinces = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCitySelect(id_province) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/city/select/" + id_province, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.cities = response.data;
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getSchOrigin() {
      try {
        const response = await axios.get("/api/sch/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.schs = response.data;
        //console.log(this.schs);

        this.loading_schs = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getEduInfo() {
      try {
        const response = await axios.get(
          "/api/eduinfobyreg/" + this.form.id_reg,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.eduinfo = response.data;
        //console.log(response.data.nisn);

        this.form.id = response.data.id;
        this.form.id_sch = response.data.id_sch;
        this.form.nisn = response.data.nisn;
        this.form.year_graduation = response.data.year_graduation;
        this.form.certificate_date = response.data.certificate_date;
        this.form.certificate_number = response.data.certificate_number;
        this.form.un_number = response.data.un_number;
        this.form.un_date = response.data.un_date;
        this.form.skhun = response.data.skhun;
        this.form.abandoned_classes = response.data.abandoned_classes;
        this.form.reason_for_the_move = response.data.reason_for_the_move;

        this.loading_eduinfo = "";
      } catch (error) {
        console.log(error);
      }
    },
    async handleSubmit() {
      this.displayLoadingSubmit = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/update_eduinfo/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.displayLoadingSubmit = "Simpan Data";
        Swal.fire(
          "Good job!",
          "Data berhasil disimpan!<br />Lanjut ke pengisian <b>Data Orang Tua</b>",
          "success"
        );

        setTimeout(() => {
          // rediret to next form
          this.$router.push({ path: "/parentinfo" });
        }, 2000);
      } catch (error) {
        console.log(error);
        this.displayError = error.response.data.message;
        this.displayLoadingSubmit = "Simpan Data";
        setTimeout(() => {
          this.displayError = "";
        }, 5000);
      }
    },
    makeToast(variant = null, setTitle, setBody) {
      this.$bvToast.toast(setBody, {
        title: setTitle,
        variant: variant,
        solid: true,
      });
    },
    async handleAddSches() {
      this.displayLoadingSubmit2 = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/add_sches_by_reg/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.formsches,
        });
        if (response.data.status == "success") {
          let id_sch = response.data.id_sch;
          this.displayLoadingSubmit2 = "Simpan Data";
          Swal.fire(
            "Good job!",
            "Data Sekolah berhasil ditambahkan!",
            "success"
          );
          // close modal
          this.$bvModal.hide("modal-1");
          this.getSchOrigin();

          setTimeout(() => {
            this.form.id_sch = id_sch;
          }, 2000);
        } else {
          this.displayLoadingSubmit2 = "Simpan Data";
          Swal.fire("Warning!", response.data.message, "warning");
        }
      } catch (error) {
        console.log(error);
        this.displayError2 = error.response.data.message;
        this.displayLoadingSubmit2 = "Simpan Data";
        setTimeout(() => {
          this.displayError2 = "";
        }, 5000);
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.formsches.id_province);
    },
  },
};
</script>

<style>
</style>