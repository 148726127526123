<template>
  <div class="text-dark mt-5 text-left">
    <div v-if="showTable">
      <div style="max-width: 900px; margin: auto">
        <b-button variant="info" @click="showFormData">Tambah Data</b-button>
        <div class="table-responsive mt-2">
          <table class="table text-left">
            <thead class="thead-reginfo">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Hubungan</th>
                <th scope="col">Nama</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody class="tbody-reginfo text-dark">
              <tr v-for="(infamily, index) in infamilies" :key="infamily.id">
                <td>{{ index + 1 }}.</td>
                <td>{{ infamily.desc }}</td>
                <td>{{ infamily.fullname }}</td>
                <td>
                  <b-button
                    size="sm"
                    @click="showFormUpdateData(infamily.id_family)"
                    variant="dark"
                    >Edit data</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="loadingTable" class="mt-3">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>

          <br />
          <div v-if="showAvailFamily">
            <hr style="max-width: 50%" />
            <br />
            <h5>Data Orang Tua Tersedia</h5>
            <table class="table text-left">
              <thead class="thead-reginfo">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Hubungan</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody class="tbody-reginfo text-dark">
                <tr
                  v-for="(availfamily, index) in availfamilies"
                  :key="availfamily.id_family"
                >
                  <td>{{ index + 1 }}.</td>
                  <td>{{ availfamily.desc }}</td>
                  <td>{{ availfamily.fullname }}</td>
                  <td>
                    <b-button
                      size="sm"
                      @click="submitAvailFamily(availfamily.id_family)"
                      variant="info"
                      >{{ submitLabelAvailFamily }}</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <small
            ><i class="text-muted">{{ loading_show_availfamily }}</i></small
          >
        </div>
      </div>
    </div>
  
    <div v-if="loadingForm">
      <b-skeleton animation="fade" width="85%"></b-skeleton>
      <b-skeleton animation="fade" width="55%"></b-skeleton>
      <b-skeleton animation="fade" width="70%"></b-skeleton>
      <b-skeleton animation="fade" width="85%"></b-skeleton>
      <b-skeleton animation="fade" width="55%"></b-skeleton>
      <b-skeleton animation="fade" width="70%"></b-skeleton>
    </div>
    <div v-if="showForm">
      <div class="infocontent2">
        <div style="max-width: 900px; margin: auto">
          <form @submit.prevent="handleSubmit">
            <input type="hidden" v-model="form.id" />
            <input type="hidden" v-model="id_reg" />
            <input type="hidden" v-model="id_user" />

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputTextB01"
                class="col-md-2 col-form-label color-tosca"
                ><b>Hubungan Pendaftar</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.id_relation"
                  name="id_relation"
                  size="lg"
                  :options="relations"
                  required
                  :disabled="disabled_id_relation == 1"
                ></b-form-select>
                <small
                  ><i class="text-muted">{{ loading_relations }}</i></small
                >
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputpg_fullname"
                class="col-md-2 col-form-label color-tosca"
                ><b>Nama Lengkap</b></label
              >
              <div class="col-md-10">
                <input
                  v-model="form.fullname"
                  name="fullname"
                  type="text"
                  class="form-control form-control-lg"
                  id="inputpg_fullname"
                  required
                />
              </div>
            </div>
            <div class="mb-3 row form-input-label-right">
              <label
                for="inputbirthplace"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tempat Lahir</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.birthplace"
                  name="birthplace"
                  class="form-control form-control-lg"
                  id="inputbirthplace"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputbirthdate"
                class="col-md-2 col-form-label color-tosca"
                ><b>Tanggal Lahir</b></label
              >
              <div class="col-md-5">
                <div class="input-group">
                  <input class="form-control form-control-lg"  v-model="form.birthdate"  placeholder="Select date"
                  name="date" type="date" required> 
                </div>
                <!-- <div class="input-group">
                  <flat-pickr
                    v-model="form.birthdate"
                    :config="config"
                    class="form-control form-control-lg"
                    placeholder="Select date"
                    name="date"
                  >
                  </flat-pickr>
                  <div class="input-group-btn">
                    <button
                      class="btn btn-lg btn-dark"
                      type="button"
                      title="Toggle"
                      data-toggle
                    >
                      <b-icon icon="calendar3"></b-icon>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="inputWni" class="col-md-2 col-form-label color-tosca"
                ><b>Kewarganegaraan</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.wni"
                  name="wni"
                  size="lg"
                  :options="wnis"
                  required
                ></b-form-select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputReligion"
                class="col-md-2 col-form-label color-tosca"
                ><b>Agama</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.religion"
                  name="religion"
                  size="lg"
                  :options="religions"
                  required
                ></b-form-select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputEdulevel"
                class="col-md-2 col-form-label color-tosca"
                ><b>Pendidikan</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.edulevel"
                  name="edulevel"
                  size="lg"
                  :options="edulevels"
                  required
                ></b-form-select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputTextB01"
                class="col-md-2 col-form-label color-tosca"
                ><b>Pekerjaan</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.id_work"
                  name="id_work"
                  size="lg"
                  :options="works"
                  required
                ></b-form-select>
                <small
                  ><i class="text-muted">{{ loading_works }}</i></small
                >
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label class="col-md-2 col-form-label color-tosca"
                ><small><b>Nama Perusahaan/Institusi</b></small></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.company_name"
                  class="form-control form-control-lg"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputEdulevel"
                class="col-md-2 col-form-label color-tosca"
                ><b>Penghasilan per bulan</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.income"
                  size="lg"
                  :options="incomes"
                  required
                ></b-form-select>
              </div>
            </div>

            <div v-if="addinfo">
              <div class="mb-3 row form-input-label-right">
                <label
                  for="inputaddress"
                  class="col-md-2 col-form-label color-tosca"
                  ><b>Alamat</b></label
                >
                <div class="col-md-10">
                  <textarea
                    id="inputaddress"
                    class="form-control form-control-lg"
                    v-model="form.address"
                    name="address"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Provinsi</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_province"
                    class="form-control form-control-lg"
                    @change="handleSelectCity"
                    required
                  >
                    <option
                      v-for="select in provinceselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kota/Kabupaten</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_city"
                    class="form-control form-control-lg"
                    @change="handleSelectDistrict"
                    required
                  >
                    <option
                      v-for="select in cityselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kecamatan</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_district"
                    class="form-control form-control-lg"
                    @change="handleSelectSubDistrict"
                    required
                  >
                    <option
                      v-for="select in districtselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row form-input-label-right">
                <label class="col-md-2 col-form-label color-tosca"
                  ><b>Kelurahan</b></label
                >
                <div class="col-md-10">
                  <select
                    v-model="form.id_subdistrict"
                    class="form-control form-control-lg"
                    required
                  >
                    <option
                      v-for="select in subdistrictselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label class="col-md-2 col-form-label color-tosca"
                ><b>Kodepos</b></label
              >
              <div class="col-md-10">
                <input
                  type="text"
                  v-model="form.postalcode"
                  name="postalcode"
                  class="form-control form-control-lg"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputpg_cellphone"
                class="col-md-2 col-form-label color-tosca"
                ><b>Handphone</b></label
              >
              <div class="col-md-10">
                <input
                  id="inputcellphone"
                  type="text"
                  v-model="form.cellphone"
                  name="cellphone"
                  class="form-control form-control-lg"
                  required
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label class="col-md-2 col-form-label color-tosca"
                ><b>Email</b></label
              >
              <div class="col-md-10">
                <b-form-input
                  type="email"
                  v-model="form.email"
                  size="lg"
                  :disabled="parentAuth.id_user == family.id_user"
                  required
                ></b-form-input>
              </div>
            </div>

            <div class="text-right mt-4">
              <b-button
                pill
                variant="outline-secondary"
                type="button"
                @click="showTableData"
                style="padding-left: 30px; padding-right: 30px"
                >Kembali</b-button
              >
              {{ " " }}
              <b-button
                pill
                variant="info"
                type="submit"
                size="lg"
                style="padding-left: 30px; padding-right: 30px"
                >{{ submitLabel }}</b-button
              >
              <br />
              <small
                ><i class="text-danger">{{ isError }}</i></small
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";

export default {
  name: "ParentProfileStudentTables",
  components: {
    // flatPickr,
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      auth: localStorage.getItem("auth"),
      token: localStorage.getItem("token"),
      loadingTable: true,
      isError: "",
      infamilies: [],
      availfamilies: [],
      showAvailFamily: false,
      family: [],
      showTable: true,
      showForm: false,
      loadingForm: false,
      addinfo: true,
      id_reg: "",
      id_user: "",
      form: {
        id: "",
        fullname: "",
        birthplace: "",
        birthdate: "",
        address: "",
        id_province: "",
        id_city: "",
        id_district: "",
        id_subdistrict: "",
        postalcode: "",
        cellphone: "",
        email: "",
        company_name: "",
        id_relation: "",
        id_work: "",
        wni: "",
        religion: "",
        edulevel: "",
        income: "",
      },
      parentAuth: [],
      loading_relations: "",
      loading_works: "",
      loading_show_availfamily: "",
      works: [],
      relations: [],
      wnis: [
        {
          value: "WNI",
          text: "Warga Negara Indonesia (WNI)",
        },
        {
          value: "WNA",
          text: "Warga Negara Asing (WNA)",
        },
      ],
      religions: [
        {
          value: "Islam",
          text: "Islam",
        },
        {
          value: "Kristen",
          text: "Kristen",
        },
        {
          value: "Katolik",
          text: "Katolik",
        },
        {
          value: "Hindu",
          text: "Hindu",
        },
        {
          value: "Budha",
          text: "Budha",
        },
        {
          value: "Konghuchu",
          text: "Konghuchu",
        },
      ],
      edulevels: [
        {
          value: "SD",
          text: "Sekolah Dasar (SD) / Sederajat",
        },
        {
          value: "SMP",
          text: "Sekolah Menengah Pertama (SMP) / Sederajat",
        },
        {
          value: "SMA",
          text: "Sekolah Menengah Atas (SMA) / Sederajat",
        },
        {
          value: "D3",
          text: "Diploma",
        },
        {
          value: "S1",
          text: "Sarjana (S1)",
        },
        {
          value: "S2",
          text: "Magister (S2)",
        },
        {
          value: "S3",
          text: "Doktor (S3)",
        },
        {
          value: "LAIN",
          text: "Lainnya",
        },
      ],
      incomes: [
        {
          value: "Tidak ada",
          text: "Tidak ada",
        },
        {
          value: "< 5.000.000",
          text: "< 5.000.000",
        },
        {
          value: "5.000.000 - 10.000.000",
          text: "5.000.000 - 10.000.000",
        },
        {
          value: "10.000.000 - 15.000.000",
          text: "10.000.000 - 15.000.000",
        },
        {
          value: "15.000.000 - 20.000.000",
          text: "15.000.000 - 20.000.000",
        },
        {
          value: "20.000.000 - 25.000.000",
          text: "20.000.000 - 25.000.000",
        },
        {
          value: "25.000.000 - 30.000.000",
          text: "25.000.000 - 30.000.000",
        },
        {
          value: "> 30.000.000",
          text: "> 30.000.000",
        },
      ],
      provinceselect: [],
      cityselect: [],
      districtselect: [],
      subdistrictselect: [],

      submitLabel: "Simpan Data",
      submitLabelAvailFamily: "Gunakan Data Ini",

      disabled_id_relation: 0,
    };
  },
  created() {
    this.defaultVar();
    this.getInFamilies();
    this.getWorks();
    this.getAvailFamilies();
    this.getProvinceSelect();
  },
  methods: {
    defaultVar() {
      this.dataAuth = JSON.parse(this.auth);
      this.id_user = this.dataAuth.id_user;
      this.id_reg = this.dataAuth.id_reg;
      let parent = this.$store.state.parent;
      let parentAuth = JSON.parse(parent.auth);
      this.parentAuth = parentAuth;
    },
    clearForm() {
      let form = this.form;
      for (let x in form) {
        this.form[x] = "";
      }
    },
    showFormData() {
      this.clearForm();
      this.showForm = true;
      this.showTable = false;
      this.disabled_id_relation = 0;
    },
    showTableData() {
      this.showForm = false;
      this.showTable = true;
      this.getInFamilies();
    },
    showFormUpdateData(id) {
      this.form.id = id;
      this.showTable = false;
      this.getFamily();
    },
    async getProvinceSelect() {
      try {
        const response = await axios.get("api/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.provinceselect = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCitySelect(id_province) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/city/select/" + id_province, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cityselect = response.data;
        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getDistrictSelect(id_city) {
      this.loadingModal = true;
      try {
        const response = await axios.get("api/district/select/" + id_city, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.districtselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },
    async getSubDistrictSelect(id_district) {
      this.loadingModal = true;
      try {
        const response = await axios.get(
          "api/subdistrict/select/" + id_district,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.subdistrictselect = response.data;

        this.loadingModal = false;
      } catch (error) {
        console.log(error);
        this.loadingModal = false;
      }
    },

    async getRelations() {
      try {
        const response = await axios.get("/api/select_relations");
        let relations = response.data;
        //console.log(relations);

        /*
        // filter relations from infamilies
        for(let x in this.infamilies) {
          for(let y in this.relations) {
            console.log(this.infamilies[x].id_relation+' '+this.relations[y].value)
          }
        }
        */

        let new_relation = [];
        //let validate_relation = [];
        let i = 0;
        for (let x in relations) {
          let valid = true;
          for (let y in this.infamilies) {
            //console.log(this.relations[x].value+' '+this.infamilies[y].id_relation)
            if (relations[x].value == this.infamilies[y].id_relation) {
              valid = false;
            }
            //console.log(this.relations[x].value)
          }
          if (valid == true) {
            new_relation[i] = {
              value: relations[x].value,
              text: relations[x].text,
              disabled: false,
            };
            //new_relation[i] = relations[x];
          } else {
            new_relation[i] = {
              value: relations[x].value,
              text: relations[x].text,
              disabled: true,
            };
          }
          i++;
        }
        //console.log(new_relation.length);

        if (new_relation.length == 0) {
          this.relations = relations;
        } else {
          this.relations = new_relation;
        }
        //console.log(this.relations);

        //console.log(this.relations);
        //console.log(this.infamilies);

        this.loading_relations = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getWorks() {
      try {
        const response = await axios.get("/api/select_works");
        this.works = response.data;
        //console.log(this.relations);

        this.loading_works = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getFamily() {
      this.loadingForm = true;
      try {
        const response = await axios({
          method: "post",
          url: "/api/getfamily/" + this.form.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id_reg: this.id_reg,
          },
        });


        this.family = response.data;
        //console.log(this.family);
        for (let x in this.family) {
          this.form[x] = this.family[x];
        }
        if (this.parentAuth.id_user == this.family.id_user) {
          this.form.email = this.parentAuth.email;
        }

        this.getCitySelect(this.form.id_province);
        this.getDistrictSelect(this.form.id_city);
        this.getSubDistrictSelect(this.form.id_district);

        this.disabled_id_relation = 1;

        this.loadingForm = false;
        this.showForm = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getInFamilies() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/getinfamilies/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        this.infamilies = response.data;
        this.getRelations();
        this.loadingTable = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getAvailFamilies() {
      this.loading_show_availfamily = "loading data..";
      try {
        const response = await axios({
          method: "get",
          url: "/api/getavailfamilies/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        this.availfamilies = response.data.result;
        if (this.availfamilies.length > 0) {
          this.showAvailFamily = true;
        } else {
          this.showAvailFamily = false;
        }
        this.loading_show_availfamily = "";
      } catch (error) {
        console.log(error);
        this.loading_show_availfamily = "";
      }
    },

    async submitAvailFamily(id_fam) {
      this.submitLabelAvailFamily = "process..";
      try {
        const response = await axios({
          method: "post",
          url: "/api/submitavailfamily/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id_family: id_fam,
          },
        });
        console.log(response);
        this.submitLabelAvailFamily = "Gunakan Data Ini";
        this.getInFamilies();
        this.getAvailFamilies();

        Swal.fire(
          "Good job!",
          //"Data berhasil disimpan!<br />Lanjut ke pengisian <b>Data Kesehatan</b>",
          "Data berhasil disimpan!</b>",
          "success"
        );

        // update store refresh_headeruserinfo
        this.$store.commit("true_refresh_headeruserinfo");

        this.$emit("rerender_headeruserinfo", false);
        setTimeout(() => {
          this.$emit("rerender_headeruserinfo", true);
          // rediret to next form
          //this.$router.push({ path: "/medicalinfo" });
        }, 2000);
      } catch (error) {
        console.log(error);
        this.submitLabelAvailFamily = "Gunakan Data Ini";
      }
    },

    async handleSubmit() {
      this.submitLabel = "processing...";
     console.log(this.birthdate)

      try {
        const response = await axios({
          method: "post",
          url: "/api/update_infamilies/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.submitLabel = "Simpan Data";
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else if (response.data.status == "success") {
          //this.showTableData();
          Swal.fire(
            "Good job!",
            //"Data berhasil disimpan!<br />Lanjut ke pengisian <b>Data Kesehatan</b>",
            "Data berhasil disimpan!",
            "success"
          );

          // update store refresh_headeruserinfo
          this.$store.commit("true_refresh_headeruserinfo");

          this.showForm = false;
          this.showTable = true;
          this.getInFamilies();

          this.$emit("rerender_headeruserinfo", false);
          setTimeout(() => {
            this.$emit("rerender_headeruserinfo", true);
            // rediret to next form
            //this.$router.push({ path: "/medicalinfo" });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", error, "error");
      }
    },
    makeToast(variant = null, setTitle, setBody) {
      this.$bvToast.toast(setBody, {
        title: setTitle,
        variant: variant,
        solid: true,
      });
    },
    handleSelectCity() {
      this.getCitySelect(this.form.id_province);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.id_city);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.id_district);
    },
  },
};
</script>

<style>
</style>