import Vue from 'vue'
import VueRouter from 'vue-router'
//import Frontend from '../views/Frontend.vue'
//import Backend from '../views/Backend.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Recovery from '../views/Recovery.vue'
import Lock from '../views/Lock.vue'
import Logout from '../views/Logout.vue'
import Parentguard from '../views/Parentguard.vue'
import RegList from '../views/RegList.vue'
import ParentProfile from '../views/ParentProfile.vue'
import GoToChild from '../views/GoToChild.vue'
import GoToParent from '../views/GoToParent.vue'
//import RegInternal from '../views/RegInternal.vue'
//import RegGuideInternal from '../views/RegGuideInternal.vue'
//import RegInternalConfirmData from '../views/RegInternalConfirmData.vue'
import SuccessSubmitDataSendNotify from '../views/SuccessSubmitDataSendNotify.vue'
//import RegExternal from '../views/RegExternal.vue'
import RegGuide from '../views/RegGuide.vue'
import Dashboard from '../views/Dashboard.vue'
import SchoolRuleInfo from '../views/SchoolRuleInfo.vue'
import FinanceRuleInfo from '../views/FinanceRuleInfo.vue'
import EducationInfo from '../views/EducationInfo.vue'
import LivingInfo from '../views/LivingInfo.vue'
import ParentInfo from '../views/ParentInfo.vue'
import MedicalInfo from '../views/MedicalInfo.vue'
import AchievementInfo from '../views/AchievementInfo.vue'
import ParentAgreementInfo from '../views/ParentAgreementInfo.vue'
import UploadDocument from '../views/UploadDocument.vue'
import PrintProofRegister from '../views/PrintProofRegister.vue'
import PrintProofPayment from '../views/PrintProofPayment.vue'
import PrintProfileRegister from '../views/PrintProfileRegister.vue'
import PassLetterInfo from '../views/PassLetterInfo.vue'
import EmptyToken from '../views/EmptyToken.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: "/login",
    name: 'Home',
    component: Home
  },  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lock',
    name: 'Lock',
    component: Lock
  },      
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/recovery',
    name: 'Recovery',
    component: Recovery
  },   
  {
    path: '/regguide',
    name: 'RegGuide',
    component: RegGuide
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },  
  {
    path: '/educationinfo',
    name: 'EducationInfo',
    component: EducationInfo
  },  
  {
    path: '/livinginfo',
    name: 'LivingInfo',
    component: LivingInfo
  },
  {
    path: '/parentinfo',
    name: 'ParentInfo',
    component: ParentInfo
  },
  {
    path: '/medicalinfo',
    name: 'MedicalInfo',
    component: MedicalInfo
  },
  {
    path: '/achievementinfo',
    name: 'AchievementInfo',
    component: AchievementInfo
  },          
  {
    path: '/upload_document',
    name: 'UploadDocument',
    component: UploadDocument
  },
  {
    path: '/successsubmitdatasendnotify',
    name: 'SuccessSubmitDataSendNotify',
    component: SuccessSubmitDataSendNotify,
  },
  {
    path: '/parentguard',
    name: 'Parentguard',
    component: Parentguard
  },
  {
    path: '/parentguard/:tabindex',
    name: 'Parentguard',
    component: Parentguard
  },
  {
    path: '/reglist',
    name: 'RegList',
    component: RegList
  },
  {
    path: '/parentprofile',
    name: 'ParentProfile',
    component: ParentProfile
  },
  {
    path: '/gotochild',
    name: 'GoToChild',
    component: GoToChild
  },
  {
    path: '/gotoparent',
    name: 'GoToParent',
    component: GoToParent
  },  
  {
    path: '/printproofpayment',
    name: 'PrintProofPayment',
    component: PrintProofPayment
  },
  {
    path: '/printproofregister',
    name: 'PrintProofRegister',
    component: PrintProofRegister
  },
  {
    path: '/printprofileregister',
    name: 'PrintProfileRegister',
    component: PrintProfileRegister
  },
  {
    path: '/schoolruleinfo',
    name: 'SchoolRuleInfo',
    component: SchoolRuleInfo
  },      
  {
    path: '/parentagreementinfo',
    name: 'ParentAgreementInfo',
    component: ParentAgreementInfo
  },      
  {
    path: '/financeruleinfo',
    name: 'FinanceRuleInfo',
    component: FinanceRuleInfo
  },
  {
    path: '/passletterinfo',
    name: 'PassLetterInfo',
    component: PassLetterInfo
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },        
  {
    path: '/emptytoken',
    name: 'EmptyToken',
    component: EmptyToken
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
