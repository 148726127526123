<template>
  <div>
    <div class="reginfo2">
      <div class="infotitle">
        <b-container>
          <b-row>
            <b-col cols="1"> </b-col>
            <b-col cols="11" class="text-left">
              <h4>DATA PRESTASI</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="infocontent2 text-left">
        <div v-if="showForm">
          <form @submit.prevent="handleSubmit">
            <input type="hidden" v-model="form.id" />
            <input type="hidden" v-model="form.id_reg" />
            <input type="hidden" v-model="form.id_user" />

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputcategories"
                class="col-md-2 col-form-label color-tosca"
                ><b>Kategori</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.category"
                  name="category"
                  size="lg"
                  :options="categories"
                  required
                ></b-form-select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputlevels"
                class="col-md-2 col-form-label color-tosca"
                ><b>Level</b></label
              >
              <div class="col-md-10">
                <b-form-select
                  v-model="form.level"
                  name="level"
                  size="lg"
                  :options="levels"
                  required
                ></b-form-select>
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label for="year" class="col-md-2 col-form-label color-tosca"
                ><b>Tahun</b></label
              >
              <div class="col-md-3">
                <input
                  v-model="form.year"
                  type="number"
                  class="form-control form-control-lg"
                />
              </div>
            </div>

            <div class="mb-3 row form-input-label-right">
              <label
                for="inputachievement"
                class="col-md-2 col-form-label color-tosca"
                ><b>Prestasi</b></label
              >
              <div class="col-md-10">
                <b-form-textarea
                  id="textarea"
                  v-model="form.achievement"
                  placeholder="Jelaskan singkat tentang prestasi Anak Anda..."
                  rows="3"
                  max-rows="6"
                  size="lg"
                ></b-form-textarea>
              </div>
            </div>

            <div class="text-right">
              <b-button
                pill
                variant="outline-secondary"
                type="button"
                @click="showTableData"
                style="padding-left: 30px; padding-right: 30px"
                >Cancel</b-button
              >
              {{ " " }}
              <button class="btn btn-info btn-lg" type="submit">
                {{ displayLoadingSubmit }}
              </button>
              <br />
              <br />
              <small
                ><i class="text-danger">{{ displayError }}</i></small
              >
              <i class="text-success">{{ displaySuccess }}</i>
            </div>
          </form>
        </div>
        <div v-if="showTable">
          <b-button @click="showFormData(0)" variant="primary"
            ><b-icon icon="plus-circle"></b-icon> Tambah Data</b-button
          >
          <table class="table text-left mt-2">
            <thead class="thead-reginfo">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Prestasi</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody class="tbody-reginfo text-dark">
              <tr
                v-for="(achievementinfo, index) in achievementinfos"
                :key="achievementinfo.id"
              >
                <td>{{ index + 1 }}.</td>
                <td>
                  Tahun: <b>{{ achievementinfo.year }}</b
                  ><br />
                  Kategori : <b>{{ achievementinfo.category }}</b
                  ><br />
                  Level : <b>{{ achievementinfo.level }}</b
                  ><br />
                  Prestasi : <b>{{ achievementinfo.achievement }}</b>
                </td>
                <td>
                  <b-button-group>
                    <b-button
                      size="sm"
                      @click="showFormData(achievementinfo.id)"
                      variant="info"
                      >Edit data</b-button
                    >
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="
                        handleDelete(
                          achievementinfo.id,
                          achievementinfo.achievement
                        )
                      "
                      ><b-icon icon="trash"></b-icon> Hapus</b-button
                    >
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center mt-3">
            <div v-if="loadingAchievementlist">
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
            </div>
            <div v-if="noDataTable">
              <i class="text-muted"
                >Belum ada data,<br />click
                <b-link @click="showFormData">disini</b-link> untuk tambah
                data</i
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AchievementProfileStudent",
  data() {
    return {
      achievementinfos: [],
      showForm: false,
      showTable: true,
      loadingAchievementlist: true,
      noDataTable: false,
      form: {
        id: "",
        id_user: "",
        id_reg: "",
        category: "",
        level: "",
        year: "",
        achievement: "",
      },
      categories: [
        {
          value: "Akademik",
          text: "Akademik",
        },
        {
          value: "Non Akademik",
          text: "Non Akademik",
        },
      ],
      levels: [
        {
          value: "Kabupaten/Kota",
          text: "Kabupaten/Kota",
        },
        {
          value: "Provinsi",
          text: "Provinsi",
        },
        {
          value: "Nasional",
          text: "Nasional",
        },
        {
          value: "Internasional",
          text: "Internasional",
        },
      ],
      displayLoadingSubmit: "Simpan",
      displayError: "",
      displaySuccess: "",
      dataupdate: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.showAchievementTable();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.dataAuth = JSON.parse(localStorage.getItem("auth"));
      this.form.id_user = this.dataAuth.id_user;
      this.form.id_reg = this.dataAuth.id_reg;
    },
    async showAchievementTable() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/achievementinfos/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        this.achievementinfos = response.data;
        this.loadingAchievementlist = false;
        if (this.achievementinfos.length == 0) {
          this.noDataTable = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showTableData() {
      this.showForm = false;
      this.showTable = true;
    },
    async showFormData(id = "") {
      this.showForm = true;
      this.showTable = false;

      this.form.id = "";
      this.form.category = "";
      this.form.level = "";
      this.form.year = "";
      this.form.achievement = "";

      if (id != "") {
        this.form.id = id;
        try {
          const response = await axios({
            method: "post",
            url: "/api/getachievementinfodata/" + id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
              id_reg: this.form.id_reg,
            },
          });

          //console.log(response.data);

          this.form.category = response.data.category;
          this.form.level = response.data.level;
          this.form.year = response.data.year;
          this.form.achievement = response.data.achievement;
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleDelete(id, info) {
      Swal.fire({
        title: "Are you sure?",
        html: "Data ini akan dihapus!<br /><b>" + info + "</b>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRow(id);
        }
      });
    },
    async deleteRow(id) {
      try {
        const response = await axios({
          method: "get",
          url: "/api/delete_achievementinfos/" + this.form.id_reg + "/" +id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data)
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        this.showAchievementTable();

      } catch (error) {
        console.log(error);
        Swal.fire("Error!", error.message, "error");
      }
    },
    async handleSubmit() {
      this.displayLoadingSubmit = "processing...";
      try {
        const response = await axios({
          method: "post",
          url: "/api/update_achievementinfos/" + this.form.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.dataupdate = response.data;
        this.displayLoadingSubmit = "Simpan Data";
        //console.log(response.data);
        if(response.data.status == 'error') {
          Swal.fire("Error!", "Maaf, data error!", "error");
        }else{
          Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          this.showTableData();
          this.showAchievementTable();
        }
        
        this.noDataTable = false;
      } catch (error) {
        console.log(error);
        this.displayError = error.response.data.message;
        this.displayLoadingSubmit = "Simpan Data";
        setTimeout(() => {
          this.displayError = "";
        }, 5000);
      }
    },
  },
};
</script>

<style>
</style>