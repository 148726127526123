<template>
  <div class="container text-center mt-5 text-muted">
    <i>please wait for redirection...</i><br />
  </div>
</template>

<script>
export default {
  name: "GoToParent",
  data() {
    return {
      token: "",
      auth: "",
      payload: "",
      fullname: "",
      id_user: "",
      id_reg: "",
      noreg: "",
      format_birthdate: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.switchToken();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.payload = JSON.parse(localStorage.getItem("payload"));
      this.fullname = this.auth.fullname;
      this.id_user = this.auth.id_user;
    },
    switchToken() {
      // save to localStorage
        let parent = this.$store.state.parent;
        let auth = parent.auth;
        let payload = parent.payload;
        let token = parent.token;
        
        localStorage.setItem("token", token);
        localStorage.setItem("auth", auth);
        localStorage.setItem("payload", payload);

        this.$store.commit('logout');
        // save to Vuex
        let dataVuex = {
          token: token,
          auth: auth,
          payload: payload,
        };
        this.$store.commit("login", dataVuex);
        // redirect
        this.$router.push({ path: "/reglist" });
        
    }
  },
};
</script>

<style>
</style>