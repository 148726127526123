<template>
  <div>
    <div class="table-responsive">
      <b-button-group>
        <b-button
          variant="danger"
          squared
          size="md"
          :to="{ path: '/dashboard' }"
          ><b-icon icon="file-earmark-medical"></b-icon>{{ " " }} Lengkapi
          Biodata</b-button
        >
        <b-button
          variant="success"
          squared
          size="md"
          :to="{ path: '/upload_document' }"
          ><b-icon icon="cloud-upload"></b-icon>{{ " " }} Upload
          Dokumen</b-button
        >
        <b-button
        v-show="false"
          variant="info"
          squared
          size="md"
          @click.prevent="handlePrintProofRegister"
          ><b-icon icon="printer"></b-icon>{{ " " }} {{ printProofRegisterButtonLabel }}</b-button
        >
        <b-button v-show="false" variant="warning" squared size="md" class="text-white"
          ><b-icon icon="printer"></b-icon>{{ " " }} Print Bukti
          Pembayaran</b-button
        >
        <b-button v-show="false" squared size="md" @click.prevent="handlePrintProfileRegister"
          ><b-icon icon="printer"></b-icon>{{ " " }} {{ printProfileRegisterButtonLabel }}</b-button
        >
      </b-button-group>
    </div>

    <div class="table-responsive mt-4" v-if="showChildMenu">
      <b-button-group>
        <b-button variant="primary" :to="{ path: '/dashboard' }"
          >Biodata Siswa</b-button
        >
        <b-button variant="success" :to="{ path: '/educationinfo' }"
          >Data Pendidikan</b-button
        >
        <b-button variant="danger" :to="{ path: '/parentinfo' }"
          >Data Orang Tua</b-button
        >
        <b-button variant="warning" :to="{ path: '/livinginfo' }"
          >Data Tempat Tinggal</b-button
        >
        <b-button variant="info" :to="{ path: '/medicalinfo' }"
          >Data Kesehatan</b-button
        >
        <b-button variant="primary" :to="{ path: '/achievementinfo' }"
          >Data Prestasi</b-button
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "FlowMenuRegister",
  data() {
    return {
      role: "",
      token: "",
      auth: "",
      id_reg: "",
      id_user: "",
      noreg: "",
      dataAuth: [],
      basePath: axios.defaults.baseURL,
      showChildMenu: true,
      printProofRegisterButtonLabel: "Print Bukti Pendaftaran",
      printProfileRegisterButtonLabel: "Print Biodata Pendaftar",
    };
  },
  created() {
    this.defaultVar();
    this.checkShowChildMenu();
  },
  methods: {
    defaultVar() {
      this.dataAuth = JSON.parse(localStorage.getItem("auth"));
      this.noreg = this.dataAuth.noreg;
      this.id_user = this.dataAuth.id_user;
      this.id_reg = this.dataAuth.id_reg;
      this.role = this.dataAuth.role;

      let parent = this.$store.state.parent;
      if (parent.token != "") {
        this.parentlogged = true;
      }
    },
    checkShowChildMenu() {
      let curRoute = this.$router.currentRoute.path;
      if(curRoute == '/upload_document') {
        this.showChildMenu = false;
      }else{
        this.showChildMenu = true;
      }
    },
    async handlePrintProofRegister() {
      this.printProofRegisterButtonLabel = "Loading...";
      try {
        await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.printProofRegisterButtonLabel = "Print Bukti Pendaftaran";
        // if (response.data.valid == false) {
        //   Swal.fire({
        //     title: "Perhatian!",
        //     html: "Mohon lengkapi data Calon Siswa terlebih dahulu, silakan cek Status Kelengkapan Biodata!",
        //     icon: "info",
        //   });
        // } else {
        //   let random = Math.random().toString(16).slice(2);
        //   let urlparentagreement = this.basePath + "/api/reg/proofregister/" + this.noreg + "/" + random;
        //   window.open(urlparentagreement);
        // }
        let random = Math.random().toString(16).slice(2);
          let urlparentagreement = this.basePath + "/api/reg/proofregister/" + this.noreg + "/" + random;
          window.open(urlparentagreement);
      } catch (error) {
        console.log(error);
        this.printProofRegisterButtonLabel = "Print Bukti Pendaftaran";
      }
    },
    async handlePrintProfileRegister() {
      this.printProfileRegisterButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.printProfileRegisterButtonLabel = "Print Biodata Pendaftaran";
        if (response.data.valid == false) {
          Swal.fire({
            title: "Perhatian!",
            html: "Mohon lengkapi data Calon Siswa terlebih dahulu, silakan cek Status Kelengkapan Biodata!",
            icon: "info",
          });
        } else {
          let random = Math.random().toString(16).slice(2);
          let urlparentagreement = this.basePath + "/api/reg/profileresume/" + this.noreg + "/" + random;
          window.open(urlparentagreement);
        }
      } catch (error) {
        console.log(error);
        this.printProfileRegisterButtonLabel = "Print Biodata Pendaftaran";
      }
    },
  },
};
</script>

<style>
</style>