<template>
  <b-container style="max-width: 1400px">
    <DashboardHeader />
    <div class="banner-headline text-center">
      <div class="banner-headline-content">
        <h4 class="text-white text-center">PRINT BUKTI PEMBAYARAN</h4>
        <div class="reg-container mt-4">
            <div class="dashboard-content">
            <div class="text-right">
                <b-link>
                    <b-icon icon="printer" alt="Printer" font-scale="3"></b-icon>
                </b-link>{{" "}}
                <b-link :to="{path:'/dashboard'}">
                    <b-icon icon="x" variant="danger" font-scale="4"></b-icon>
                </b-link>                
            </div>

            </div>
        </div>

      </div>
    </div>

    <HomeFooter />
  </b-container>

</template>

<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "PrintProofPayment",
  components: {
    DashboardHeader,
    HomeFooter,
  },
};
</script>

<style>
</style>