<template>
  <div class="login-form shadow">
    <b-overlay
      :show="loaderShow"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        title="Card with overlay"
        :aria-hidden="loaderShow ? 'true' : null"
      >
        <b-row>
          <b-col>
            <b-alert v-if="showLabelAlert" show :variant="variantLabelAlert">{{
              messageLabelAlert
            }}</b-alert>
            <img :alt="altlogo" :src="logo" width="200" />
          </b-col>
        </b-row>
        <br />
        <b class="text-success" style="font-size: 20px">{{ themesapp.name }}</b>
        <form @submit.prevent="handleSubmit" class="mt-3">
          <div class="form-group">
            <input
              v-model="form.usercode"
              name="username"
              type="text"
              class="form-control form-control-lg"
              id="username"
              placeholder="username/email"
              required
            />
          </div>
          <div class="form-group">
            <input
              v-model="form.password"
              name="password"
              type="password"
              class="form-control form-control-lg"
              id="exampleInputPassword1"
              placeholder="password"
              required
            />
          </div>
          <b-button pill block variant="success" type="submit" size="lg">{{
            isLoading
          }}</b-button>
          <br />
          <pre class="text-danger">{{ isError }}</pre>
        </form>
        <div class="login-form-bottom-text">
          <h5>
            Anda belum punya akun?<br />
            <small
              >Silahkan daftar
              <b-link :to="{ path: '/register' }">disini</b-link>.</small
            ><br />
            <small
              >Lupa password? klik
              <b-link :to="{ path: '/recovery' }">disini</b-link></small
            >
          </h5>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        usercode: "",
        password: "",
      },
      isLoading: "Login",
      isError: "",
      loaderShow: false,
      themesapp: [],
      basePath: axios.defaults.baseURL,
      logo: "",
      altlogo: "",
      showLabelAlert: false,
      variantLabelAlert: "danger",
      messageLabelAlert: "",
    };
  },
  created() {
    this.defaultVar();
  },
  methods: {
    defaultVar() {
      localStorage.setItem("token", "");
      localStorage.setItem("auth", "");

      this.themesapp = this.$store.getters.themesapp;
      this.altlogo = this.themesapp.name;
      this.logo = this.basePath + "/uploads/settingapps/" + this.themesapp.logo;
    },
    hideLabelAlert() {
      this.showLabelAlert = false;
      this.variantLabelAlert = "";
      this.messageLabelAlert = "";
    },
    showLabelAlertDanger() {
      this.showLabelAlert = true;
      this.variantLabelAlert = "danger";
      this.messageLabelAlert = "Bad Login Access!";
      setTimeout(() => {
        this.hideLabelAlert();
      }, 5000);
    },
    showLabelAlertSuccess() {
      this.showLabelAlert = true;
      this.variantLabelAlert = "success";
      this.messageLabelAlert = "Well done!, redirection..";
      setTimeout(() => {
        this.hideLabelAlert();
      }, 5000);
    },
    async handleSubmit() {
      this.loaderShow = true;
      try {
        this.isLoading = "process..";
        const response = await axios({
          method: "post",
          url: "/api/signin",
          data: {
            usercode: this.form.usercode,
            email: this.form.usercode,
            username: this.form.usercode,
            password: this.form.password,
          },
        });
        this.showLabelAlertSuccess();

        // save to localStorage
        let dataAuth = JSON.stringify(response.data.auth);
        let dataPayload = JSON.stringify(response.data.payload);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("auth", dataAuth);
        localStorage.setItem("payload", dataPayload);
        // save to Vuex
        let dataVuex = {
          token: response.data.access_token,
          auth: dataAuth,
          payload: dataPayload,
        };
        this.$store.commit("login", dataVuex);
        //console.log(role);

        // redirect
        setTimeout(() => {
          if (response.data.auth.role == "Student") {
            this.$router.push({ path: "/dashboard" });
          } else if (response.data.auth.role == "Parentguard") {
            this.$router.push({ path: "/reglist" });
          }
        }, 2000);
      } catch (error) {
        this.loaderShow = false;
        this.isLoading = "Login";
        this.showLabelAlertDanger();
        /*
        let errMessage = "";
        const status = error.response.status;
        errMessage = "[" + status + "]";
        const errUsername = error.response.data.username;
        switch (status) {
          case 422:
            errMessage += errUsername;
            break;
          case 401:
            errMessage +=
              error.response.data.error + "\nInvalid password login.";
            break;
          case 404:
            errMessage += error.response.data.error;
            break;
        }
        //this.isError = errMessage;
        console.log(errMessage);
        setTimeout(() => {
          this.isError = "";
        }, 5000);

        this.isLoading = "Login";
        console.log(error.response);
        */
      }
    },
  },
};
</script>

<style>
</style>