<template>
  <div class="dashboard-content">
    <h4 class="color-tosca">INFORMASI CALON SISWA</h4>
    <div class="infogreen">
      <b class="color-tosca">Assalamualaikum, {{ fullname }} [{{ noreg }}]</b>
      <span v-if="parentlogged"
        >&nbsp;&nbsp;&nbsp;<b-button
          :to="{ path: '/gotoparent' }"
          size="sm"
          variant="danger"
          ><b-icon-link></b-icon-link> Menu Orang Tua</b-button
        ></span
      >
    </div>
    <div class="mt-2">
      <b>Status: </b>
      <b-badge :variant="statusVariant">{{ status === 'Registered' ? 'Terdaftar' : status === 'Active' ? 'Aktif' : status === 'Passed' ? 'Lulus' : status === 'Conditional Active Student' ? 'Lulus Bersyarat' : status === 'Backup Active Student' ? 'Lulus Cadangan' : status === 'Fail' ? 'Tidak Lulus' : status === 'Active Student' ? 'Siswa Aktif' : '-' }}</b-badge
      ><br />
      <!-- <b>Paidlist: </b> <i class="text-muted">{{ paidlist }}</i
      >{{ " " }}
      <b-badge :variant="paidlistStatusVariant">{{ paidlistStatus }}</b-badge
      ><br /> -->
      <b>Stasus Kelengkapan Biodata: </b>
      <span v-for="mandatory in mandatoryData" v-bind:key="mandatory.nik">
        <span v-if="mandatory['value'] == true"
          ><b-badge variant="success">{{ mandatory["label"] }}</b-badge
          >&nbsp;</span
        ><span v-else
          ><b-badge variant="danger" v-b-tooltip.hover title="Mohon dilengkapi"
            >{{ mandatory["label"] }}
            <b-icon-exclamation-circle></b-icon-exclamation-circle></b-badge
          >&nbsp;</span
        >
      </span>
    </div>
    <br />
    <div>
      <div class="table-responsive">
        <b-button-group>
          <b-button
            variant="primary"
            @click.prevent="handleSchoolRuleInfo"
            size="md"
            ><b-icon icon="printer"></b-icon>
            {{ schoolRuleInfoButtonLabel }}</b-button
          >{{ " " }}
          <b-button
            variant="success"
            @click.prevent="handleFinanceRuleInfo"
            size="md"
            ><b-icon icon="printer"></b-icon>
            {{ financeRuleInfoButtonLabel }}</b-button
          >{{ " " }}
          <b-button
            variant="warning"
            class="text-white"
            @click.prevent="handleParentAgreementInfo"
            size="md"
            ><b-icon icon="printer"></b-icon>
            {{ parentAgreementInfoButtonLabel }}</b-button
          >{{ " " }}
          <b-button
            variant="info"
            class="text-white"
            @click.prevent="handlePrintProofRegister"
            size="md"
            ><b-icon icon="printer"></b-icon>
            {{ printProofRegisterButtonLabel }}</b-button
          >{{ " " }}
          <b-button
            class="text-white"
            @click.prevent="handlePrintProfileRegister"
            size="md"
            ><b-icon icon="printer"></b-icon>
            {{ printProfileRegisterButtonLabel }}</b-button
          >
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "HeaderUserInfo",
  data() {
    return {
      role: "",
      token: "",
      auth: "",
      id_reg: "",
      noreg: "",
      fullname: "Loading, please wait..",
      headerRegInfo: [],
      status: "",
      statusVariant: "",
      paidlist: "Loading, please wait..",
      paidlistStatus: "",
      paidlistStatusVariant: "success",
      parentlogged: false,
      mandatoryData: [],
      basePath: axios.defaults.baseURL,
      schoolRuleInfoButtonLabel: "Tata Tertib Sekolah",
      financeRuleInfoButtonLabel: "Tata Tertib Keuangan",
      parentAgreementInfoButtonLabel: "Pernyataan Orang Tua",
      printProofRegisterButtonLabel: "Print Bukti Pendaftaran",
      printProfileRegisterButtonLabel: "Print Biodata Pendaftar",

    };
  },
  created() {
    this.defaultVar();
    this.getHeaderRegInfo();
    //this.checkRefreshHeaderUserInfo();
  },
  methods: {
    defaultVar() {
      this.dataAuth = JSON.parse(localStorage.getItem("auth"));
      this.id_user = this.dataAuth.id_user;
      this.id_reg = this.dataAuth.id_reg;
      this.role = this.dataAuth.role;

      let parent = this.$store.state.parent;
      if (parent.token != "") {
        this.parentlogged = true;
      }
    },
    checkRefreshHeaderUserInfo() {
      let status_refresh_headeruserinfo =
        this.$store.getters.refresh_headeruserinfo;
      console.log(status_refresh_headeruserinfo);
    },
    warningMandatory(data) {
      let curRoute = this.$router.currentRoute.path;
      console.log(curRoute);
      let showWarningMandatory = false;
      let dataMandatory = "";
      for (let x in data) {
        if (data[x].value == false) {
          dataMandatory += data[x].desc + "<br />";
          showWarningMandatory = true;
        }
      }

      if (showWarningMandatory == true) {
        console.log("Mohon lengkapi data Calon Siswa! " + dataMandatory);
        /*
        Swal.fire({
          title: "Perhatian!",
          html:
            "Mohon lengkapi data Calon Siswa! <br /><small><b>" +
            dataMandatory +
            "</b></small>",
          icon: "info",
        });
        */
      }
    },
    async getHeaderRegInfo() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/getheaderreginfo/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        this.headerRegInfo = response.data.reg;
        console.log(this.headerRegInfo);
        this.noreg = this.headerRegInfo.noreg;
        this.fullname = this.headerRegInfo.fullname;
        this.status = this.headerRegInfo.status;
        this.paidlist = this.headerRegInfo.paidlist;
        this.paidlist_status = this.headerRegInfo.paidlist_status;
        this.mandatoryData = this.headerRegInfo.mandatory_data;
        //console.log(this.mandatoryData);
        this.warningMandatory(this.mandatoryData);

        switch (this.paidlist_status) {
          case "Lunas":
            this.paidlistStatus = "Lunas";
            this.paidlistStatusVariant = "success";
            break;
          case "Belum Lunas":
            this.paidlistStatus = "Belum Lunas";
            this.paidlistStatusVariant = "danger";
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleSchoolRuleInfo() {
      this.schoolRuleInfoButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "api/settingapps/schoolruleinfo/read?reg="+this.dataAuth.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let resdata = response.data;

        let urlPDF = resdata["fileurl"];
        let randomNumber = Date.now();
        window.open(urlPDF + "?rand=" + randomNumber);
        this.schoolRuleInfoButtonLabel = "Tata Tertib Sekolah";
      } catch (error) {
        console.log(error);
        this.schoolRuleInfoButtonLabel = "Tata Tertib Sekolah";
      }
    },
    async handleFinanceRuleInfo() {
      this.financeRuleInfoButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "api/settingapps/financeruleinfo/read",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let resdata = response.data;
        let urlPDF = resdata["fileurl"];
        let randomNumber = Date.now();
        window.open(urlPDF + "?rand=" + randomNumber);
        this.financeRuleInfoButtonLabel = "Tata Tertib Keuangan";
      } catch (error) {
        console.log(error);
        this.financeRuleInfoButtonLabel = "Tata Tertib Keuangan";
      }
    },
    async handleParentAgreementInfo() {
      this.parentAgreementInfoButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.parentAgreementInfoButtonLabel = "Pernyataan Orang Tua";
        if (response.data.valid == false) {
          Swal.fire({
            title: "Perhatian!",
            html: "Mohon lengkapi data Calon Siswa terlebih dahulu, silakan cek Status Kelengkapan Biodata!",
            icon: "info",
          });
        } else {
          let random = Math.random().toString(16).slice(2);
          let urlparentagreement = this.basePath + "/api/reg/parentagreement/" + this.noreg + "/" + random;
          window.open(urlparentagreement);
        }
      } catch (error) {
        console.log(error);
        this.parentAgreementInfoButtonLabel = "Pernyataan Orang Tua";
      }
    },
    async handlePrintProofRegister() {
      this.printProofRegisterButtonLabel = "Loading...";
      try {
        await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.printProofRegisterButtonLabel = "Print Bukti Pendaftaran";
        // if (response.data.valid == false) {
        //   Swal.fire({
        //     title: "Perhatian!",
        //     html: "Mohon lengkapi data Calon Siswa terlebih dahulu, silakan cek Status Kelengkapan Biodata!",
        //     icon: "info",
        //   });
        // } else {
        //   let random = Math.random().toString(16).slice(2);
        //   let urlparentagreement = this.basePath + "/api/reg/proofregister/" + this.noreg + "/" + random;
        //   window.open(urlparentagreement);
        // }
        let random = Math.random().toString(16).slice(2);
          let urlparentagreement = this.basePath + "/api/reg/proofregister/" + this.noreg + "/" + random;
          window.open(urlparentagreement);
        
      } catch (error) {
        console.log(error);
        this.printProofRegisterButtonLabel = "Print Bukti Pendaftaran";
      }
    },
    async handlePrintProfileRegister() {
      this.printProfileRegisterButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "/api/checkvalidmandatorydata/" + this.id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.printProfileRegisterButtonLabel = "Print Biodata Pendaftaran";
        if (response.data.valid == false) {
          Swal.fire({
            title: "Perhatian!",
            html: "Mohon lengkapi data Calon Siswa terlebih dahulu, silakan cek Status Kelengkapan Biodata!",
            icon: "info",
          });
        } else {
          let random = Math.random().toString(16).slice(2);
          let urlparentagreement = this.basePath + "/api/reg/profileresume/" + this.noreg + "/" + random;
          window.open(urlparentagreement);
        }
      } catch (error) {
        console.log(error);
        this.printProfileRegisterButtonLabel = "Print Biodata Pendaftaran";
      }
    },
  },
};
</script>

<style>
</style>