<template>
  <div></div>
</template>

<script>
import axios from "axios";

export default {
  name: "Auth",
  data() {
    return {
      token: "",
    };
  },
  created() {
    this.checkRole();
  },
  methods: {
    checkRole() {
      this.auth = localStorage.getItem("auth");
      //console.log('auth: '+this.auth);
      if((this.auth != '') && (this.auth != null)) {
        let dataAuth = JSON.parse(this.auth);
        let role = dataAuth.role;
        let curRoute = this.$router.currentRoute.path;
        console.log('role: '+role);
        //console.log(curRoute);
        if (
          curRoute !== "/login" &&
          curRoute !== "/register" &&
          curRoute !== "/logout"
        ) {
          
          switch (curRoute) {
            case "/dashboard":
              if (role == "Parentguard") {
                this.$router.push({ path: "/reglist" });
              }
              break;
            case "/parentguard":
              if (role == "Student") {
                this.$router.push({ path: "/dashboard" });
              }
              break;
          }
          
        }
      }
    },
    async checkToken() {
      try {
        const response = await axios({
          method: "get",
          url: "/api/checktoken/",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        //let status = response.data.status;
        console.log(response.data.status);
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push({ path: "/logout" });
        }
      }
    },
  },
};
</script>

<style>
</style>