<template>
  <div class="reginfo">
      <b-row>
          <b-col md="2" class="img-icon-info">
              <img alt="Support" src="../assets/img/support.svg" width="150" class="img-fluid" />
          </b-col>
          <b-col md="10">
              <p><b>HARAP DIPEPRHATIKAN..!!</b></p>
          <ol>
              <li>Baca panduan pendaftaran di Link berikut ini (<b-link class="text-white" :to="{ path: '/regguide' }"><u><b>Panduan Pendaftaran</b></u></b-link>).</li>
              <li>Isi data Formulir berikut dengan benar.</li>
              <li>Hubungi Customer Service Online kami (0813-7755-9918) bila Anda mengalami kesulitan.</li>
          </ol>
          </b-col>          
      </b-row>
  </div>
</template>

<script>
export default {
    name: "RegInfoInternal",
}
</script>

<style>

</style>